import React, { FC, useCallback, useEffect, useState } from "react";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Button from "../../components/bootstrap/Button";
import MySelect from "../../components/bootstrap/forms/Select";
// @ts-ignore
import Select from "react-select";
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle,
} from "../../components/bootstrap/OffCanvas";
import Input from "../../components/bootstrap/forms/Input";
import Textarea from "../../components/bootstrap/forms/Textarea";
import {
  FormikHelpers,
  useFormik,
  FormikValues,
  Field,
  useFormikContext,
} from "formik";
import Spinner from "../../components/bootstrap/Spinner";
import {
  addProjectMember,
  fetchDepartmentUsers,
  getClientRoles,
  getCostTypes,
  getDepartmentList,
  getStatuses,
  getSysvineRoles,
  updateProjectDetail,
  deleteProjectMember,
  getGroups
} from "../../common/data/service";
import { getLoggedInUser } from "../../common/data/commonService";
import { IMainUsers } from "../../model/MainUsers";
import useDarkMode from "../../hooks/useDarkMode";
import DatePickerIcon from "../../components/bootstrap/forms/DatePickerIcon";
import { validateExpiredToken } from "../../App/Constants";
import { useNavigate } from "react-router-dom";
import UseColourStyles from "./SelectStyles"
// Define interfaces for data structures
interface User {
  id: string;
  userfullname: string;
  requisition_code: string;
}
interface SysvineRole {
  id: string;
  name: string;
}
interface ClientRole {
  id: string;
  name: string;
}
interface Status {
  id: string;
  name: string;
}
interface Department {
  id: string;
  deptname: string;
}
interface Group {
  displayName: string;
  name: string;
} 

interface Role {
  value?: string;
  label?: string;
}

interface SelectedDepartment {
  id?: string;
  deptname?: string;
}
let dummyflag = 1
// Create the AddProjectMemberPopup component
const AddProjectMemberPopup = (props: any) => {
  // Extract the projectId from props
  const projectId = props.projectId;
  const requisitionId = props.id;
  const navigate = useNavigate();
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const [isLoadingSysvineRoles, setIsLoadingSysvineRoles] = useState(false);
  const [isLoadingClientRoles, setIsLoadingClientRoles] = useState(false);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const [isLoadingLoggedInUser, setIsLoadingLoggedInUser] = useState(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [groupsList, setGroupsList] = useState<Group[]>([]);
  const colourStyles = UseColourStyles();
  const [requisitionDisabled, setRequisitionDisabled] = useState(false);
  const [requisitionRequired, setRequisitionRequired] = useState(true);

  useEffect(() => {
    // Fetch department list when the component mounts
    setIsLoadingDepartment(true);
    setIsLoadingSysvineRoles(true);
    setIsLoadingClientRoles(true);
    setIsLoadingStatuses(true);
    setIsLoadingLoggedInUser(true);
    setIsLoadingGroups(true);
    getDepartmentList({})
      .then((res) => {
        setdepartments(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingDepartment(false);
      });

    // Fetch Sysvine roles

    getSysvineRoles()
      .then((res) => {
        setsysvineRoles(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingSysvineRoles(false);
      });

    // Fetch client roles

    getClientRoles()
      .then((res) => {
        setclientRoles(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingClientRoles(false);
      });

    // Fetch statuses

    getStatuses()
      .then((res) => {
        setstatuses(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoadingStatuses(false);
      });

    // Fetch details of the logged-in user

    getLoggedInUser()
      .then((res) => {
        setUserDetails(res?.data);
      })
      .catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoadingLoggedInUser(false);
      });
      getCostTypes()
      .then((res) => {
        setCostTypes(res?.data);
      })
      .catch((e) => {
        console.log(e.message);
      })
      getGroups({projectId: projectId})
      .then((res) => {
        setGroupsList(res?.data?.groups);
      })
      .catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoadingGroups(false);
      });
  }, [navigate, projectId]);

  const [selectedClientRole, setSelectedClientRole] = useState<Role>({});
  const [selectedSysvineRole, setSelectedSysvineRole] = useState<Role>({});
  const [selectedDepartment, setSelectedDepartment] = useState<SelectedDepartment>({}); 
  const [selectedStatus, setSelectedStatus] = useState<string>("");

  let requisitionFlag = requisitionId

  const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] =
    useState(false);
  const [clientRoles, setclientRoles] = useState<ClientRole[]>([]);
  const [departments, setdepartments] = useState<Department[]>([]);
  const [statuses, setstatuses] = useState<Status[]>([]);
  const [sysvineRoles, setsysvineRoles] = useState<SysvineRole[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [iscalled, setIsCalled] = useState<boolean>(false);
  const [costTypes, setCostTypes] = useState<ClientRole[]>([]);

  const { themeStatus, darkModeStatus } = useDarkMode();

  // Destructure props to access the listPageProps object
  const { listPageProps } = props;

  const [userDetails, setUserDetails]: any = useState<IMainUsers[]>([]);

  // Define roles
  const roles = ["LEAD", "DL", "DH"];

  // Validation function for the Description field
  const validateDescription = (description: string | any[]) => {
    const MAX_WORDS = 250;
    const errors = { Description: "" };

    if (description.length >= MAX_WORDS) {
      errors.Description = `Description should not exceed ${MAX_WORDS} characters.`;
    }

    return errors;
  };


  const formik = useFormik({
    validate: (values) => {
      validateDescription(values.Description);
    },

    async onSubmit<Values>(
      values: FormikValues,
      formikHelpers: FormikHelpers<Values>
    ): Promise<void | Promise<any>> {
      let isValid = false;
      if ((values.Name === "" || values.StartDate === null || values.Status === "" || (props.groupAccess && values.Status !== "Ended" && values.group.length === 0) || (values.Status === "Ended" && !values.EndDate)) && !requisitionDisabled) {
        setShowAlert(true);
      }
      else {
        if (values.Name === "" || values.Status === "") {
          setShowAlert(true);
          isValid = false;
        }else{
        isValid = true;
        }
      }

      try {
        if (isValid) {
          const matchingClientRole = clientRoles.find((role) => role.name === values.ClientRole);
          if (matchingClientRole) {
            values.ClientRole = matchingClientRole.id;
          }

          const matchingSysvineRole = sysvineRoles.find((role) => role.name === values.SysvineRole);
          if (matchingSysvineRole) {
            values.SysvineRole = matchingSysvineRole.id;
          }

          const matchingStatus = statuses.find((status) => status.name === values.Status);
          if (matchingStatus) {
            values.Status = matchingStatus.id;
          }
          setIsLoading(true);
          // Call the API to add a project member
          await addProjectMember({
            projectId: projectId,
            requisition: requisitionDisabled,
            department: values.department,
            Name: values.Name,
            StartDate: values.StartDate,
            EndDate: values.EndDate,
            userId: userDetails.id,
            description: values.Description,
            SysvineRole: values.SysvineRole,
            ClientRole: values.ClientRole,
            Status: values.Status,
            Role: values.Role,
            costType: values.costType,
            group: values.group
          });
          const requisition = requisitionId === undefined ? false : true;
          if (requisition) {
            await deleteProjectMember({ id: requisitionId, requisition: true });
          }
          setIsLoading(false);
          props.handleSearchClick("", "Added");

          // Close the Offcanvas after the operation is completed
          setUpcomingEventsEditOffcanvas(false);
        }


      } catch (error) {
        console.error("API error:", error);
      }
    },

    initialValues: {
      department: "",
      Name: "",
      ClientRole: props.client_role?.name ?? "",
      Status: props.status?.name ?? "",
      SysvineRole: props.sysvine_role?.name ?? "",
      StartDate: new Date(),
      EndDate: "",
      Description: "",
      Dept: "",
      Title: "",
      selectedDepartment: null as Department | null,
      costType: "",
      group: []
    },
  });

  // Function to fetch users based on the selected department
  const fetchUsers = useCallback(async (departmentId: string) => {
    setIsLoading(true);
    const requisitionData = requisitionId === undefined ? true : false;
    try {
      const usersData = await fetchDepartmentUsers({
        projectId: projectId,
        departmentId: departmentId,
        requisitionFlag: requisitionData
      });
      setIsCalled(true);
      setUsers(usersData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      // Handle the error as needed in your component.
    }
  }, [projectId, requisitionId]);

  useEffect(() => {
    if (requisitionFlag !== undefined && dummyflag === 1)
    {
      setSelectedClientRole({
        value: props?.client_role?.id,
        label: props?.client_role?.name,
      });
      setSelectedDepartment({
        id: props?.main_department?.id,
        deptname: props?.main_department?.deptname
      })
      fetchUsers(props?.main_department?.id as string)
      formik.values.department = props?.main_department?.id
      setSelectedSysvineRole({
        value: props?.sysvine_role?.id,
        label: props?.sysvine_role?.name,
      });
    }
  }, [props?.client_role, props?.sysvine_role, requisitionFlag, props.department, props, props?.main_department, formik.values, fetchUsers]);

  const groupOptions = groupsList ? groupsList.map((group) => ({
    label: group.displayName,
    value: group.name,
  })) : [];

  const handleNameOptionChange = (value: any, label: any) => {
    if (value === label) { 
      setRequisitionDisabled(true);
      setRequisitionRequired(false)
    } else {
      setRequisitionDisabled(false);
      setRequisitionRequired(true)
    }
  };

  useEffect(() => {
    // Trigger user fetching when the selected department changes
    if (formik.values.Dept !== "") {
      setIsLoading(true);
      console.log(formik.values.Dept);
      fetchUsers(formik.values.Dept);
    }
  }, [formik.values.Dept, projectId, fetchUsers]);

  useEffect(() => {
    // Show the offcanvas when the component mounts
    setUpcomingEventsEditOffcanvas(true);
  }, []);

  const [showMessage, setShowMessage] = useState(false);

  // Handle Description field change and character limit
  const handleDescriptionChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    const truncatedValue = inputValue.slice(0, 250);
    formik.setFieldValue('Description', truncatedValue);

    if (inputValue.length < 250) {
      formik.handleChange(event);
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  };



  // Handle Client Role change
  const handleClientChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    if (inputValue != "") {
      formik.handleChange(event);
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  };

  // Map department options for the Select component
  const departmentOptions = departments.map((department) => ({
    label: department.deptname,
    value: department.id.toString(),
  }));

  // Map user options for the Select component
  const NameOptions = users.map((user) => ({
    label: user.requisition_code || user.userfullname,
    value: user.requisition_code || user.id.toString(),
  }));
  const nameValue = NameOptions.find((nameOption) => nameOption.value == formik.values.Name);

  // Map client role options for the Select component
  const ClientOptions = clientRoles.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));
  const clientValue = ClientOptions.find((ClientOption) => ClientOption.value == formik.values.ClientRole);

  // Map Sysvine role options for the Select component
  const SysvineRoleOptions = sysvineRoles.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));
  const roleValue = SysvineRoleOptions.find((SysvineRoleOption) => SysvineRoleOption.value == formik.values.SysvineRole);
  const [showAlert, setShowAlert] = useState(false);
  const costTypeOptions = costTypes.map((type) => ({
    label: type.name,
    value: type.id.toString(),
  }));
  // Handle closing the offcanvas
  const handleOffCanvasClose = () => {
    props.addNewProjMemberDetails();
  };

  const costTypeValue = costTypeOptions.find((costTypeOption) => costTypeOption.value == formik.values.costType);
  return (
    <>
      <OffCanvas
        setOpen={setUpcomingEventsEditOffcanvas}
        isOpen={upcomingEventsEditOffcanvas}
        onClose={handleOffCanvasClose}
        titleId="upcomingEdit"
        isBodyScroll
        placement="end"
      >
        <OffCanvasHeader setOpen={setUpcomingEventsEditOffcanvas}>
          <OffCanvasTitle id="..."> Add Project Member Form </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody className="...">
          <div className="row g-4">
            <div className="col-12">
              <FormGroup id="Dept" label="Department" isRequired={true}>
                <Select
                  options={[{ value: "", label: 'Please Select' }, ...departmentOptions]}
                  isSearchable={true}
                  placeholder="Search and choose..."
                  value= {requisitionFlag !== undefined && dummyflag === 1 ? {value: selectedDepartment.id,label: selectedDepartment.deptname } : formik.values.department === "" ? null : undefined }
                  onChange={(selectedOption: { value: any }) => {
                    if (selectedOption && selectedOption) {
                      formik.setFieldValue("Name", "");
                      if(selectedOption.value!=''){
                      fetchUsers(selectedOption.value);}
                      if (requisitionFlag === undefined){
                        formik.setFieldValue("department", selectedOption.value);
                      }else{
                        formik.setFieldValue("department", selectedOption.value);
                        requisitionFlag = undefined;
                        dummyflag = 2;
                      }
                      
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  required // Add the required attribute
                  styles={colourStyles}
                />
              </FormGroup>
            </div>

            <div className="col-12">
              <FormGroup id="Name" label="Name" isRequired={true}>
                <Select
                  options={[{ value: null, label: 'Please Select' }, ...NameOptions]}
                  isSearchable={true}
                  isDisabled={!formik.values.department}
                  value = {nameValue || null }
                  placeholder={formik.values.department ? 'Search and choose...' : 'Please Select a Department'}
                  onChange={(selectedOption: { value: any, label: any }) => {
                    if (selectedOption) {
                      formik.setFieldValue("Name", selectedOption.value);
                      handleNameOptionChange(selectedOption.value, selectedOption.label);
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  styles={colourStyles}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup id="Sysvine Role" label="Role">
                <Select
                options={[{ value: null, label: 'Please Select' }, ...SysvineRoleOptions]}
                  isSearchable={true}
                  placeholder="Search and choose..."
                  value={roleValue || (selectedSysvineRole.value ? {label: selectedSysvineRole.label,value: selectedSysvineRole.value} : null)}
                  onChange={(selectedOption: { value: any }) => {
                    if (selectedOption) {
                      formik.setFieldValue("SysvineRole", selectedOption.value);
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  styles={colourStyles}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup id="Client Role" label="Client Role">
                <Select
                  options={[{ value: null, label: 'Please Select' }, ...ClientOptions]}
                  isSearchable={true}
                  placeholder="Search and choose..."
                  value={clientValue || (selectedClientRole.value ? {label: selectedClientRole.label,value: selectedClientRole.value} : null)}
                  onChange={(selectedOption: { value: any }) => {
                    if (selectedOption) {
                      formik.setFieldValue("ClientRole", selectedOption.value);
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  styles={colourStyles}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup id="Cost Type" label="Cost Type">
                <Select
                  options={[{ value: null, label: 'Please Select' }, ...costTypeOptions]}
                  isSearchable={true}
                  placeholder="Search and choose..."
                  onChange={(selectedOption: { value: any }) => {
                    if (selectedOption) {
                      formik.setFieldValue("costType", selectedOption.value);
                    } else {
                      console.log("No option selected.");
                    }
                  }}
                  value={costTypeValue || null}
                  styles={colourStyles}
                />
              </FormGroup>
            </div>
            {props.groupAccess && (
             <div className="col-12">
               <FormGroup id="Google Groups" label="Google Groups" isRequired={formik.values.Status != "Ended" && requisitionRequired}>
                 <Select
                 closeMenuOnSelect={false}
                   options={groupOptions}
                   isDisabled= {requisitionDisabled}
                   isSearchable={true}
                   isMulti
                   placeholder="Search and choose..."
                   onChange={(selectedOption: { value: any }) => {
                     if (selectedOption) {
                       formik.setFieldValue("group", selectedOption);
                     } else {
                      formik.setFieldValue("group", []);
                     }
                   }}
                   styles={colourStyles}
                 />
               </FormGroup>
             </div>
             )}
            <div className="col-12">
              <FormGroup label="Start Date"  isRequired={requisitionRequired} className="datepicker-container">
                <br />
                <DatePicker
                  color={process.env.REACT_APP_PRIMARY_COLOR}
                  showIcon
                  wrapperClassName="calendar-icon-color"
                  disabled= {requisitionDisabled}
                  className="processvine-form-font-weight form-control "
                  selected={formik.values.StartDate}
                  onChange={(date: any) => formik.setFieldValue("StartDate", date)}
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="Click to select a date"
                  icon={<DatePickerIcon />}
                  maxDate={formik.values.EndDate}
                />
              </FormGroup>
            </div>

            <div className="col-12">
              <FormGroup label="End Date" className="datepicker-container" isRequired={formik.values.Status === "Ended"}>
                <DatePicker
                  showIcon
                  className="processvine-form-font-weight form-control"
                  selected={formik.values.EndDate}
                  minDate={formik.values.StartDate}
                  disabled= {requisitionDisabled}
                  onChange={(date: any) => formik.setFieldValue("EndDate", date)}
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="Click to select a date"
                  icon={<DatePickerIcon />}
                />
              </FormGroup>
            </div>

            <div className="col-12">
              <FormGroup id="Status" label="Status" isRequired={true}>
                <MySelect
                  className="processvine-form-font-weight"
                  ariaLabel="Status"
                  placeholder="Select Status"
                  onChange={formik.handleChange}
                  value={formik.values.Status}
                  list={[
                    { value: "", text: "Please Select" },
                    ...statuses.map((status) => ({
                      value: status.name,
                      text: status.name,
                    })),
                  ]}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup
                label={`Description (Note: Max 250 Characters allowed)`}
              >
                <Textarea
                  className="processvine-form-font-weight"
                  rows={8}
                  id="Description"
                  name="Description"
                  maxLength={250}
                  onChange={handleDescriptionChange}
                  value={formik.values.Description}
                  disabled= {requisitionDisabled}
                />
              </FormGroup>
              {showMessage && (
                <p className='description-validation'>
                  Exceeded the 250 Characters limit.
                </p>
              )}
            </div>
          </div>
        </OffCanvasBody>
        {showAlert && (
          <span className="kpi-container-message"> {"Please Fill Mandatory Fields(*)"} </span>
        )}
        <>
          <div className='row m-0'>
            <div className='col-12 p-3'>
              <Button
                icon="save"
                className="w-100 btn btn-light-info"
                isLight
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
        </>
        {(isLoading || isLoadingDepartment || isLoadingSysvineRoles || isLoadingClientRoles || isLoadingStatuses || isLoadingLoggedInUser || isLoadingGroups) && (          <div className="processvine-overlay-box">
            <Spinner
              className="text-center processvine-no-projects processvine-spinner"
              inButton
            />
          </div>
        )}
      </OffCanvas>
    </>
  );
};

export default AddProjectMemberPopup;