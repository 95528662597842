import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import Card, { CardBody, CardFooter, CardFooterLeft, CardLabel, CardTitle, CardHeader, CardActions } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Button from '../../components/bootstrap/Button';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { employeeBoardingHRTitle } from '../../App/Constants';
import Spinner from '../../components/bootstrap/Spinner';
import { dashboardMenu } from '../../menu';
import { useToasts } from 'react-toast-notifications';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import { employeeDetailsByID, getAllDivisions, updateDivision } from '../../common/data/empService';
import format from 'date-fns/format'; 
import Multiselect from 'multiselect-react-dropdown';
import './EmpStyles.css';


const MainEmployeeBoardingHRPage = () => {

  type TTabs = 'Basics';
  interface ITabs {
    [key: string]: TTabs;
  }

  const TABS: ITabs = {

    EMPLOYEEDETAILS: 'Basics'

  };

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const empId = params.get('empid');
  const decodeBase64 = (encodedString: string) => {
    try {
      return window.atob(encodedString);
    } catch (error) {
      return '';
    }
  };

  const employeeId = empId ? decodeBase64(empId) : '';
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TTabs>(TABS.PROJECTDETAILS);
  const [isEditing, setIsEditing] = useState(false);
  const [editAccess] = useState<boolean>(true);
  const flag = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addToast, removeToast } = useToasts();
  const previousEmployeeId = useRef("");

 
  interface FormValues {

    id: string;
    userId: string;
    prefixName: string;
    firstName: string;
    lastName: string;
    dateOfJoining: string;
    reportingManagerName: string;
    empStatusId: string;
    employemntStatus: string;
    employeeId: string;
    emailAddress: string;
    departmentName: string;
    divisionId: string
    divisionCode: string [];
    jobtitleName: string;
    positionName: string;
    isActive: string;
    personaleEmail: string;
    legalName: string;

  }


  const formik = useFormik<FormValues>({

    onSubmit: async (values) => {
      console.log('Form Submitted:', values);
      setIsLoading(true);
  
      
       const formattedDivisions = selectedDivisions.map((div) => ({
        id: String(div.id),
        code: div.key,  
        isActive: div.active ? 1 : 0, 
      }));

  

      try {
        let response;
        
        const divisionsToSend = formattedDivisions.some((div) => div.code === 'None') 
  ? formattedDivisions.every((div) => div.code === 'None') 
    ? []  
    : formattedDivisions.filter((div) => div.code !== 'None') 
  : formattedDivisions;

        
        response = await updateDivision(values.userId, divisionsToSend);


        console.log('API Response:', response);

        setIsEditing(false);
        setIsLoading(false);

        addToast(response.data, {
          id: 'ACCESS_DENIED',
          appearance: 'success',
          autoDismiss: true,
        });

        flag.current = true;
        refetchEmpDetailsById(); // fetch employee details based on ID

      } catch (error) {

        setIsEditing(false);
        setIsLoading(false);
        console.error('Error:', error);
        addToast('Something went wrong!', {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      return undefined;
    },

    initialValues: {
      id: '',
      userId: '',
      prefixName: '',
      firstName: '',
      lastName: '',
      dateOfJoining: '',
      reportingManagerName: '',
      empStatusId: '',
      employemntStatus: '',
      employeeId: '',
      emailAddress: '',
      departmentName: '',
      divisionId: '',
      divisionCode: [],
      jobtitleName: '',
      positionName: '',
      isActive: '',
      personaleEmail: '',
      legalName: '',
    },
  });


  useEffect(() => {
    return () => {

      removeToast('ACCESS_DENIED');
    };
  }, [removeToast]);


  useEffect(() => {
    if (flag.current) {

      if (!employeeId || previousEmployeeId.current === employeeId) return;

      previousEmployeeId.current = employeeId;

      const fetchEmployeeData = async () => {
        flag.current = false;
        setIsLoading(true);

        try {

          const res = await employeeDetailsByID(employeeId);
          const data = res?.data;

          if (!data) {
            console.error('No employee data found');
            setIsLoading(false);
            return;
          }

          console.log(data);

          const divisionData = data?.division_json ? JSON.parse(data.division_json) : [];

          const divisionCode = divisionData
            .sort((a: { code: any; }, b: { code: any; }) => {
             
              const codeA = String(a.code || "").toLowerCase();
              const codeB = String(b.code || "").toLowerCase();
              
              return codeA.localeCompare(codeB); 
            })
            .map((item: { isActive: number; code: any; }) => {
              if (item.isActive === 0) {
                return `${item.code} (Deactivated)`;
              }
              return item.code;
            })
            .join(', ');

         
          formik.setValues({
            id: data?.id || "",
            userId: data?.user_id || "",
            prefixName: data?.prefix_name || "",
            firstName: data?.firstname || "",
            lastName: data?.lastname || "",
            dateOfJoining: data?.date_of_joining
              ? format(new Date(data?.date_of_joining), 'dd-MMM-yyyy')
              : "-",
            reportingManagerName: data?.reporting_manager_name || "",
            empStatusId: data?.emp_status_id || "",
            employemntStatus: data?.employemnt_status || "",
            employeeId: data?.employeeId || "",
            emailAddress: data?.emailaddress || "",
            departmentName: data?.department_name || "",
            divisionId: data?.division_id || "",
            divisionCode: divisionCode,
            jobtitleName: data?.jobtitle_name || "",
            positionName: data?.position_name || "",
            isActive: data?.isactive || "",
            personaleEmail: data?.personalemail || "",
            legalName: data?.legal_name || "",
          });

          setIsLoading(false);


        } catch (error) {
          console.error('Error fetching employee data:', error);
          setIsLoading(false);
        }
      };

      fetchEmployeeData();
    }

  }, [employeeId, formik]);

  const Loader = () => (
    <div className='processvine-overlay-box'>
      <Spinner
        className='text-center processvine-no-projects processvine-spinner'
        inButton
      />
    </div>
  );

  const [division, setdivision] = useState<any[]>([]);
  useEffect(() => {
    if (isEditing) {
      setIsLoading(true);
      getAllDivisions()
        .then((res) => {
          setdivision(res?.data)
        })

        .catch((e) => {
          console.log('Error fetching divisions:', e.message);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false); 
        });

      console.log("API call")
    }
  }, [isEditing, navigate]);


  const refetchEmpDetailsById = () => {
    flag.current = true;
    setIsEditing(false);

    const fetchEmployeeData = async () => {
      setIsLoading(true);

      try {
        const res = await employeeDetailsByID(employeeId);
        const data = res?.data;
        const divisionData = data?.division_json ? JSON.parse(data.division_json) : [];


          const divisionCode = divisionData
            .sort((a: { code: any; }, b: { code: any; }) => {
              const codeA = String(a.code || "").toLowerCase();
              const codeB = String(b.code || "").toLowerCase();
              
              return codeA.localeCompare(codeB); // Ascending order
            })
            .map((item: { isActive: number; code: any; }) => {
              if (item.isActive === 0) {
                return `${item.code} (Deactivated)`;
              }
              return item.code;
            })
            .join(', ');


        if (data) {
          // Set form values
          formik.setValues({
            id: data?.id || "",
            userId: data?.user_id || "",
            prefixName: data?.prefix_name || "",
            firstName: data?.firstname || "",
            lastName: data?.lastname || "",
            dateOfJoining: data?.date_of_joining
              ? format(new Date(data?.date_of_joining), 'dd-MMM-yyyy')
              : "-",
            reportingManagerName: data?.reporting_manager_name || "",
            empStatusId: data?.emp_status_id || "",
            employemntStatus: data?.employemnt_status || "",
            employeeId: data?.employeeId || "",
            emailAddress: data?.emailaddress || "",
            departmentName: data?.department_name || "",
            divisionId: data?.division_id || "",
            divisionCode: divisionCode,
            jobtitleName: data?.jobtitle_name || "",
            positionName: data?.position_name || "",
            isActive: data?.isactive || "",
            personaleEmail: data?.personalemail || "",
            legalName: data?.legal_name || "",
          });
          setSelectedDivisions(divisionData.filter((item: { isActive: number; }) => item.isActive === 1).map((item: { code: any; name: any; id: any; isActive: any; }) => ({
            key: item.code,
            name: item.name || item.code,
            id: item.id,
            active: item.isActive,
          })));
  
        
        }

      } catch (error) {
        console.error('Error fetching employee data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeData();
  };

  
  const divisionOptions = division.map((div) => ({
    key: div.divisionCode,
    name: div.divisionName,
    id: div.id,
    active: div.active ? 1 : 0,
  }));

  const [selectedDivisions, setSelectedDivisions] = useState<any[]>([]); 
   
   const handleSelect = (selectedList: any[], selectedItem: any) => {
    setSelectedDivisions(selectedList); 
    formik.setFieldValue('divisionCode', selectedList.map((item) => item.key).join(','));  

  };


  const handleRemove = (selectedList: any[], removedItem: any) => {
    setSelectedDivisions(selectedList); 
    formik.setFieldValue('divisionCode', selectedList.map((item) => item.key).join(','));  

  };

 
  return (


    <PageWrapper title={employeeBoardingHRTitle}>
      <SubHeader >
        <SubHeaderLeft>
          <Breadcrumb isToHome={false}

            list={[
              {
                title: dashboardMenu.HrPage.text,
                to: `/${dashboardMenu.HrPage.subMenu.HRTeam.path}`,
                disabled: true,
              },
              {
                title: dashboardMenu.HrPage.subMenu.Employees.text,
                to: `/${dashboardMenu.HrPage.subMenu.Employees.path}`,
              },
              {
                title: TABS.EMPLOYEEDETAILS,
                to: `/${dashboardMenu.HrPage.subMenu.Employees.path}`,
              },
            ]}
          />

        </SubHeaderLeft>

      </SubHeader>
      <Page container='fluid' className={isLoading ? 'blur' : ''}>
        <div className='row h-100'>
          <div className='col-xxl-2 col-xl-2 col-lg-6'>
            <Card stretch>
              <CardHeader>
                <></>
              </CardHeader>
              <CardBody isScrollable>
                <div className='row g-3'>
                  <div className='col-12'>
                    <Button
                      color='info'
                      className='w-100 p-3'
                      isLight={TABS.EMPLOYEEDETAILS === activeTab}
                      onClick={() => {

                        setActiveTab(TABS.EMPLOYEEDETAILS);
                      }}
                    >
                      {TABS.EMPLOYEEDETAILS}
                    </Button>
                  </div>
                </div>
              </CardBody>

              <CardFooter>
                <CardFooterLeft className='w-100'>
                  <></>
                </CardFooterLeft>
              </CardFooter>
            </Card>
          </div>

          <div className={`col-xxl-10 col-xl-10 col-lg-6 ${isLoading ? 'blur' : ''}`}>

            <Card stretch tag="form" noValidate >
              {isLoading ? (<Loader />) : ('')
              }
              <CardBody isScrollable>
                <Card>
                  <CardHeader>
                    <CardLabel>
                      <CardTitle>Details</CardTitle>
                    </CardLabel>
                    <CardActions>




                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          {editAccess && (
                            <div>
                              {isEditing ? (
                                <Button
                                  color='info'
                                  className="btn btn-light-info"
                                  isLight
                                  onClick={() => {

                                    if (Object.keys(formik.errors).length === 0) {
                                      formik.handleSubmit();

                                      setIsEditing(false); 
                                    } else {
                                      console.log('No changes or form has errors, cannot submit.');
                                    }
                                  }}


                                >
                                  Update
                                </Button>
                              ) : null}
                              {!isEditing && (
                                <Button
                                  color='info'
                                  className="btn btn-light-info"
                                  isLight
                                  onClick={() => {
                                    refetchEmpDetailsById(); 
                                    flag.current = true;
                                    setIsEditing(true); 
                                    
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </div>
                          )}
                        </div>



                        {isEditing && (
                          <div style={{ marginLeft: '10px' }}> {/* Adjust the margin as needed */}
                            <Button
                              className="btn btn-light-danger"
                              onClick={refetchEmpDetailsById}

                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                      </div>

                    </CardActions>
                  </CardHeader>




                  <CardBody >

                    <div className='row g-4'>

                      {/* Emp Name */}

                     
                      <div className='col-xl-4'>
                        <FormGroup
                          label='Name'
                          labelClassName="label-font-color"
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            value={`${formik.values.firstName} ${formik.values.lastName}`}
                            readOnly
                          />
                        </FormGroup>
                      </div>
                     


                      {/* Emp id */}



                      
                      <div className='col-xl-4'>
                        <FormGroup
                          label='Employee ID'
                          labelClassName="label-font-color"
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            value={formik.values.employeeId}
                            readOnly
                          />
                        </FormGroup>
                      </div>
                      {/* )} */}


                      {/* DOJ */}

                     
                      <div className='col-xl-4'>
                        <FormGroup
                          labelClassName="label-font-color"
                          label='DOJ'
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            value={formik.values.dateOfJoining}
                            readOnly
                          />
                        </FormGroup>
                      </div>



                      {/* )} */}



                      {/* Dept */}


                      
                      <div className='col-xl-4'>
                        <FormGroup
                          labelClassName="label-font-color"
                          label='Dept'
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            readOnly
                            value={formik.values.departmentName}
                          />
                        </FormGroup>
                      </div>

                      {/* )} */}

                      {/* Division */}



      {isEditing ? (
       <div className="col-xl-4">
       <FormGroup 
         isFloating
         style={{
           backgroundColor:  '#fff', 
         }}
       >  
        <Multiselect  
     className={`processvine-form-font-weight input-box-shadow-unset grey-bg custom-multiselect ${selectedDivisions.length === 0 ? 'enlarged-padding' : ''}`}
     displayValue="key"
     selectedValues={selectedDivisions}
      onRemove={handleRemove}
      onSelect={handleSelect}
     options={divisionOptions}
     placeholder="Select Divisions"
     avoidHighlightFirstOption={true} 
/>
       </FormGroup>
     </div>
     
      ) : (
        <div className="col-xl-4">
          <FormGroup labelClassName="label-font-color" label="Divisions" isFloating>
            <Input
              className="processvine-form-font-weight input-box-shadow-unset"
              value={formik.values.divisionCode ? formik.values.divisionCode : '-'}  
              readOnly
            />
          </FormGroup>
        </div>
      )}
 


                      {/* Emp Status */}

                     
                      <div className='col-xl-4'>
                        <FormGroup
                          labelClassName="label-font-color"
                          label='Emp Status'
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            readOnly
                            value={formik.values.employemntStatus}
                          />
                        </FormGroup>
                      </div>

                      {/* )} */}


                      {/* Title */}

                     

                      <div className='col-xl-4'>
                        <FormGroup
                          labelClassName="label-font-color"
                          label='Title'
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            readOnly
                            value={formik.values.jobtitleName}
                          />
                        </FormGroup>
                      </div>
                      {/* )} */}


                      {/* Primary Position */}

                      

                      <div className='col-xl-4'>
                        <FormGroup
                          labelClassName="label-font-color"
                          label='Primary Position'
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            readOnly
                            value={formik.values.positionName}
                          />
                        </FormGroup>
                      </div>

                      {/* )} */}



                      {/* Manager */}

                     

                      <div className='col-xl-4'>
                        <FormGroup
                          labelClassName="label-font-color"
                          label='Manager'
                          isFloating>
                          <Input
                            className="processvine-form-font-weight input-box-shadow-unset"
                            readOnly
                            value={formik.values.reportingManagerName}
                          />
                        </FormGroup>
                      </div>
                      {/* )} */}

                    </div>
                  </CardBody>

                </Card>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>

  );

};

export default MainEmployeeBoardingHRPage;