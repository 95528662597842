import React, { FC, useCallback, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../components/bootstrap/Card";
import Icon from "../../components/icon/Icon";
import Button from "../../components/bootstrap/Button";
import ProjectMemberPopup from "./projectMemberPopup";
import axios from "axios";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../layout/SubHeader/SubHeader";
import Input from "../../components/bootstrap/forms/Input";
import Checks, { ChecksGroup } from "../../components/bootstrap/forms/Checks";
import USERS, { deleteProjectMember, getProjectMemberDetails, searchProjectMembers } from '../../common/data/service';
import Spinner from "../../components/bootstrap/Spinner";
import AddProjectMemberPopup from "./addprojectmemberpopup";
import { railsAPI } from "../../types";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../components/bootstrap/Modal";
import { IMainUsers } from '../../model/MainUsers';
import Alert, { AlertHeading } from "../../components/bootstrap/Alert";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import Popovers from "../../components/bootstrap/Popovers";
import { projectMemberShipTitle, validateExpiredToken } from "../../App/Constants";
import { useNavigate } from "react-router-dom";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../components/PaginationButtons";
import { dashboardMenu } from "../../menu";
import { useToasts } from "react-toast-notifications";
import ReviewHistory from "./reviewHistory";
import ProjectMemberPopupBridge from "./projectMemberPopupBridge";

// Define the ProjectMembershipDetails component
const ProjectMembershipDetails = (props: any) => {
  // State variables
  const [projMembersData, setProjMemebersData] = useState<any[]>([]);
  const [requisitionData, setRequisitionData] = useState<any[]>([]);
  const [selectRowDetails, setSelectRowDetails] = useState<any[]>([]);
  const [isProjectMemberOpen, setIsProjectMemberOpen] = useState(false);
  const [isprojectaddmemberopen, setisprojectaddmemberopen] = useState(false);
  const [viewMember, setviewMember] = useState(false);
  const { listPageProps } = props;
  const projectId = props.projectId
  const projectName = localStorage.getItem('projectName');
  const [userDetails, setUserDetails]: any = useState<IMainUsers[]>([]);
  const [closeBtn, setCloseBtn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isOperational, setIsOperational] = useState('');
  const [alert, setAlert] = useState<boolean>(false);
  const [deleted, setDeleted] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [created, setCreated] = useState(false);
  const { themeStatus, darkModeStatus } = useDarkMode();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
  const [onCurrentPageItems, setOnCurrentPageItems] = useState<any[]>([]);
  const { addToast } = useToasts();
  const [searchPerPage, setSearchPerPage] = useState<number>(PER_COUNT['10']);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [searchDatapresent, setSearchDataPresent] =useState(false);
  const [groupAccess, setGroupAccess] = useState(false);
  const [initialReqFlag, setInitialReqFlag] = useState(false);
  const [addMemberFlag, setAddMemberFlag] = useState(false);
  // Fetch data on component mount
  useEffect(() => {
    setIsLoading(true);
    getProjectMemberDetails({ projectId: projectId, projectName: projectName, requisition: true })
      .then((res) => {
        setProjMemebersData(res?.data?.data.tm_project_employees);
        setRequisitionData(res?.data?.data.tm_requisition);
        setIsLoading(false);
        setIsOperational(res?.data?.role);
        setGroupAccess(res?.data?.google_group_access)
      })
      .catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
        console.log(e.message);
      });
  }, [projectId, projectName, navigate]);

  // Function to handle search and update the member list
  const handleSearchClick = async (searchValue: any, operation?: any) => {
    if (operation === 'Added') {
      setCreated(true);
    } else if (operation === 'Updated') {
      setUpdated(true);
    }
    else if (operation == 'deleted'){
      setDeleted(true)
    }

    setTableLoading(true)
    try {
      // Use axios to fetch data from the API
      const response = await searchProjectMembers({ projectId: projectId, searchValue: searchValue, requisition: true });
      if(response.data.length===0){
        setSearchDataPresent(true)
      }
      setSearchPerPage(PER_COUNT['5']); 
      setCurrentPage(1); 
      setProjMemebersData(response.data.tm_project_employees);
      setRequisitionData(response.data.tm_requisition);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
        setCreated(false);
        setUpdated(false);
        setDeleted(false);
      }, 3000);
      setTableLoading(false)
    } catch (error) {
        navigate('/');
        window.location.reload();

      console.error("Error fetching data:", error);
    }
  };

  // Function to delete a project member
  const handleDelete = async (selectedMember: any) => {
    try {
      setTableLoading(true)
      const requisition = selectedMember.requisition_code?.present ? true : false;
      await deleteProjectMember({ id: selectedMember.id, requisition: requisition });
      handleSearchClick("","deleted")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to open the update project member details popup
  const updateProjMemberDetails = () => {
    setIsProjectMemberOpen(!isProjectMemberOpen);
  };
  const reviewHistory = () => {
    setViewHistory(!viewHistory);
  };

  // Function to open the add new project member details popup
  const addNewProjMemberDetails = () => {
    setisprojectaddmemberopen(!isprojectaddmemberopen);
  };

  // Function to format a date string
  const formatDate = (dateString: string) => {
    const timestamp = new Date(dateString);
    const day = String(timestamp.getDate()).padStart(2, '0'); // Ensure leading zero
    const month = timestamp.toLocaleString('default', { month: 'short' }).slice(0, 3);
    const year = timestamp.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };  

  // Function to handle search input change
  const handleSearchChange = (e: any) => {
    e.target.value != "" ? setCloseBtn(true) : setCloseBtn(false);
    setSearchTerm(e.target.value);
    handleSearchClick(e.target.value);
  };

  // Function to clear the search input and reset the member list
  const handleSearchClose = () => {
    setSearchTerm("");
    handleSearchClick("");
    setCloseBtn(false);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const mergedArray = [...projMembersData, ...requisitionData];
  const itemsOnPage = dataPagination(mergedArray, currentPage, searchTerm ? searchPerPage : perPage);
 
  const [viewHistory, setViewHistory] = useState(false);

  // State for loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <PageWrapper className='prms-page-wrapper'title={projectMemberShipTitle}>
      <Page>
        <Card stretch data-tour="list">
          <CardHeader>
            <CardLabel>
              <CardTitle>Members</CardTitle>
            </CardLabel>
            <CardActions>
              
                <Button
                  icon="Plus"
                  className="btn btn-light-info"
                  onClick={() => {
                    addNewProjMemberDetails();
                    setAddMemberFlag(true);
                    setInitialReqFlag(false);
                  }}
                >
                  Add New Member
                </Button>
            </CardActions>
          </CardHeader>
          <SubHeader className='div-text-box'>
            <SubHeaderLeft>
              <label
                className="border-0 bg-transparent cursor-pointer me-0"
                htmlFor="searchInput"
              >
                <Icon icon="Search" size="2x" color="primary" />
              </label>
              <Input
                id="searchInput"
                className="border-0 shadow-none bg-transparent"
                placeholder="Search Name, Department, Client Role and Status"
                value={searchTerm || ""}
                onChange={(e: any) => {
                  handleSearchChange(e);
                }}
              />
              <Button
                className={classNames(
                  { 'processvine-btn-close': !closeBtn },
                  'btn-close'
                )}
                type='button'
                aria-label='Close'
                onClick={() => handleSearchClose()}
              />
            </SubHeaderLeft>
          </SubHeader>
          <CardBody
            className="table-responsive project-membership-table-header"
            isScrollable
          >
            <table className={`table table-modern table-hover processvine-table ${tableLoading ? 'height-100' : ''}`} style={{ minWidth: "100%", width: "auto" }}>
              <thead>
                <tr>
                  <th className="prms-table-header" scope="col">
                    Name
                  </th>
                  <th scope="col" className="prms-table-header">
                    Dept
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="prms-table-header">
                    Role
                    <Icon size="lg" />
                  </th>
                  <th
                    scope="col"
                    className="prms-table-header"

                  >
                    Client Role
                    <Icon size="lg" />
                  </th>              
                  <th scope="col" className="prms-table-header">
                    Status
                    <Icon size="lg" />
                  </th>
                  <th scope="col" className="prms-table-header">
                    Start Date
                    <Icon size="lg" />
                  </th>
                  

                  
                  <th

                    scope="col"
                    className="prms-table-header"
                  >
                    Action
                    <Icon size="lg" />
                  </th>
                </tr>
              </thead>
              <tbody className={tableLoading ? 'blurred' : ''}>
                {tableLoading ? (
            <tr>
              <td  className="text-center processvine-spinner">
                <Spinner inButton />
              </td>
            </tr>
          )   : (
                  mergedArray.length > 0 ?
                    <>
                      {itemsOnPage.map((row, index) => (
                          row.requisition_code ? (
                            <tr key={index}>
                              <td>{row.requisition_code}</td>
                              <td className='center-align-table-data'>
                                {row.main_department ? row.main_department.deptname : ""}
                              </td>
                              <td className={'center-align-table-data'}>{row.sysvine_role ? row.sysvine_role.name : "-"}</td>
                              <td className={'center-align-table-data'} >{row.client_role ? row.client_role.name : "-"}</td>
                              <td className='center-align-table-data'>
                                {row.status ? row.status.name : ""}
                              </td>
                              <td></td>
                              <td className='center-align-table-data'>
                                
                                  <Popovers trigger='hover' desc='Assign a Member'>
                                    <Button
                                      color={themeStatus}
                                      icon="person-add"
                                      onClick={() => {
                                        setSelectRowDetails(row);
                                        addNewProjMemberDetails();
                                        setInitialReqFlag(true);
                                        setAddMemberFlag(false);
                                      }}
                                    ></Button>
                                  </Popovers>
                              
                                
                                  <Popovers trigger='hover' desc='Delete JR'>
                                    <Button
                                      color={themeStatus}
                                      icon="delete"
                                      onClick={() => {
                                        setSelectRowDetails(row);
                                        setModalStatus(!modalStatus);
                                      }}
                                    />
                                  </Popovers>
                                
                              </td>
                            </tr>
                          ) : (
                            <tr key={row.id}>
                              <td>{row.user ? row.user.userfullname : ""}</td>
                              <td className='center-align-table-data'>
                                {row.user ? row.user.department.deptname : ""}
                              </td>
                              <td className={row.sysvine_role ? '' : 'center-align-table-data'}>{row.sysvine_role ? row.sysvine_role.name : "-"}</td>
                              <td className={row.client_role ? '' : 'center-align-table-data'} >{row.client_role ? row.client_role.name : "-"}</td>
                              <td className='center-align-table-data'>{row.status ? row.status.name : "-"}</td>
                              <td className='center-align-table-data'>
                                {row.start_date ? formatDate(row.start_date) : "-"}
                              </td>
                              
                              <td className='center-align-table-data'>
                                
                                  <Popovers trigger='hover' desc='View'>
                                    <Button
                                      icon="Eye"
                                      color={themeStatus}
                                      onClick={() => {
                                        setviewMember(true);
                                        setIsProjectMemberOpen(!isProjectMemberOpen);
                                        setSelectRowDetails(row);
                                      }}
                                    ></Button>
                                  </Popovers>
                                  <Popovers trigger='hover' desc='Review History'>
                                    <Button
                                      icon="history"
                                      color={themeStatus}
                                      onClick={() => {
                                        setViewHistory(true);
                                        setSelectRowDetails(row);
                                      }}
                                    ></Button>
                                  </Popovers>
                                  {(isOperational === 'SUPERADMIN' || isOperational === 'ADMIN' || isOperational === 'MANAGER' || isOperational === 'LEADS') && (
                                    <Popovers trigger='hover' desc='Edit'>
                                      <Button
                                        color={themeStatus}
                                        icon="Edit"
                                        onClick={() => {
                                          setviewMember(false);
                                          updateProjMemberDetails();
                                          setSelectRowDetails(row);
                                        }}
                                      ></Button>
                                    </Popovers>
                                  )}
                                  {(isOperational === 'SUPERADMIN' || isOperational === 'ADMIN' || isOperational === 'MANAGER') && (
                                    <Popovers trigger='hover' desc='Delete'>
                                      <Button
                                        color={themeStatus}
                                        icon="delete"
                                        onClick={() => {
                                          setSelectRowDetails(row);
                                          setModalStatus(!modalStatus);
                                        }}
                                      />
                                    </Popovers>
                                  )}
                              </td>
                            </tr>
                          )
                        ))}
                    </>
                    :(
                      isLoading ? null : ( searchDatapresent? (<div className='text-center processvine-no-projects'>
                      No matches found for the searched criteria

                    </div>):(
                        <div className='text-center processvine-no-projects'>
                          No Members are Mapped to this Project
                        </div>)
                      )
                    )

                )}
              </tbody>
            </table>
            {isprojectaddmemberopen && <AddProjectMemberPopup {...listPageProps} handleSearchClick={handleSearchClick} addNewProjMemberDetails={addNewProjMemberDetails} {...(initialReqFlag && !addMemberFlag ? { ...selectRowDetails} : {})} projectId = {projectId} groupAccess = {groupAccess}/>}
            {isProjectMemberOpen && (
              <ProjectMemberPopupBridge
                {...selectRowDetails}
                updateProjMemberDetails={updateProjMemberDetails}
                viewMember={viewMember}
                handleSearchClick={handleSearchClick}
                groupAccess = {groupAccess}
                projectId = {projectId}
              />
            )}
            {viewHistory && (
    <ReviewHistory
    {...selectRowDetails}
    reviewHistory = {reviewHistory}
    />
  )}
            {(isLoading || props.load || props.isLoading)&& (
              <div className="processvine-overlay-box">
                <Spinner
                  className="text-center processvine-no-projects processvine-spinner"
                  inButton
                />
              </div>
            )}
            <Modal
              setIsOpen={setModalStatus}
              isOpen={modalStatus}
              titleId="new-todo-modal"
            >
              <ModalHeader setIsOpen={setModalStatus}>
                <ModalTitle id="new-todo-modal">
                  Project Member Delete Confirmation
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <p>
                  Are you sure you want to delete this member from this project?
                </p>
                <div className="text-center">
                  <Button
                    color="danger"
                    onClick={() => {
                      handleDelete(selectRowDetails);
                      setModalStatus(false);
                    }}
                  >
                    Delete
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button color="success" onClick={() => setModalStatus(false)}>
                    Cancel
                  </Button>
                </div>

              </ModalBody>
            </Modal>
            {
              (alert && deleted) &&
              <Alert
                icon=''
                isLight
                color='primary'
                borderWidth={0}
                className='cgp-container-alert'
              >
                <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
                  Deleted Successfully
                </AlertHeading>
              </Alert>}
            {
              (alert && created) &&
              <Alert
                icon=''
                isLight
                color='primary'
                borderWidth={0}
                className='cgp-container-alert'
              >
                <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
                  Successfully Added to the project
                </AlertHeading>
              </Alert>}
            {
              (alert && updated) &&
              <Alert
                icon=''
                isLight
                color='primary'
                borderWidth={0}
                className='cgp-container-alert'
              >
                <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
                  Updated Successfully
                </AlertHeading>
              </Alert>}
          </CardBody>
          <PaginationButtons
            setCurrentPage={handlePageChange}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            data={projMembersData}
            label="Members"
          />
        </Card>

      </Page>
    </PageWrapper>
  );
};

export default ProjectMembershipDetails;