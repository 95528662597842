import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Card, { CardBody, CardFooter, CardFooterLeft, CardLabel, CardTitle, CardFooterRight, CardHeader, CardActions } from '../../components/bootstrap/Card';
import useDarkMode from '../../hooks/useDarkMode';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import ProjectMembershipDetails from './projectMembershipDetails';
import RosterTable from './rosterTable';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Textarea from "../../components/bootstrap/forms/Textarea";
import { useLocation } from 'react-router-dom';
import Select from "../../components/bootstrap/forms/Select";
import USERS, { getBusinessUnits, getClients, getCurrencies, getDepartmentList, getProjects, getSelectedProjectDetails, updateProjectDetail, getAedAccess } from '../../common/data/service';
import { ProjectStatusOptions, ProjectType, projectMemberShipTitle, validateExpiredToken } from '../../App/Constants';
import Spinner from '../../components/bootstrap/Spinner';
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IMainUsers } from '../../model/MainUsers';
import DatePickerIcon from '../../components/bootstrap/forms/DatePickerIcon';
import AedBase from '../aed/AedBase';
import { dashboardMenu } from '../../menu';
import { useToasts } from 'react-toast-notifications';
// @ts-ignore

import { useHistory } from 'react-router-dom';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import TimeSheet from './timesheets';
// import { useHistory } from 'react-router-dom';
type TTabs = 'Members' | 'Details' | 'Roster' | 'Profile' | 'Timesheets';
interface ITabs {
  [key: string]: TTabs;
}
const MainProjectMemberPage = () => {
  const { darkModeStatus } = useDarkMode();

  const navigate = useNavigate();

  const TABS: ITabs = {
    PROJECTMEMBERS: 'Members',
    PROJECTDETAILS: 'Details',
    TRS: 'Roster',
    PROFILE: 'Profile',
    TIMESHEETS: 'Timesheets'
  };
  const location = useLocation();
  const [listPageProps, setListPageProps] = useState<any>(location?.state!);
  const [activeTab, setActiveTab] = useState<TTabs>(TABS.PROJECTDETAILS);
  const [isEditing, setIsEditing] = useState(false);
  const [changed, setChanged] = useState(false)
  const [initialValue, setInitialValue] = useState(false);
  const [businessUnits, setBusinessUnits] = useState<any[]>([]);
  const [currencies, setcurrencies] = useState<any[]>([]);
  const [clients, setclients] = useState<any[]>([]);
  const [departments, setdepartments] = useState<any[]>([]);
  const [baseProjects, setbaseProjects] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editAccess, setEditAccess] = useState<boolean>(false);
  const [projectDetails, setprojectDetails] = useState<any[]>([]);
  const [projectDet, setprojectDet] = useState<any[]>([]);
  const [projectName, setProjectName] = useState('');
  const [clientName, setClientName] = useState('');

  const flag = useRef(true);
  const [save, setSave] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const { addToast, removeAllToasts } = useToasts();
  const [load, setLoad] = useState<boolean>(false);
  const [aedAccess, setAedAccess] = useState(Object)
  const [projectDetailsReload, setProjectDetailsReload] = useState(false);
  const [isAedLoading, setIsAedLoading] = useState<boolean>(false);
  const [trsAccess, setTrsAccess] = useState<boolean>(false);
  const goToProgressVinePage = useCallback((message: string, appearance: any, delayTime = 0) => {
    setLoad(true)
    setTimeout(() => {
      navigate(`../${dashboardMenu.myjourney.subMenu.processvineListpage.path}`);
      setLoad(false)
    }, delayTime);
    setTimeout(() => {
      addToast(message, { id: 'ACCESS_DENIED', appearance: appearance, autoDismiss: true });
    }, delayTime);

  }, [navigate, addToast]);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const decodeBase64 = (encodedString: string) => {
    try {
      return window.atob(encodedString);
    } catch (error) {
      return '';
    }
  };
  const projectId = id ? decodeBase64(id) : '';




  const handleDescriptionChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    const truncatedValue = inputValue.slice(0, 500);
    formik.setFieldValue('description', truncatedValue);

    if (inputValue.length < 500) {
      formik.handleChange(event);
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  };
  function convertDate(inputDate: string | number | Date) {
    const date = new Date(inputDate);
    const formattedDate = date.toUTCString();


    return formattedDate;
  }
  interface FormValues {
    projectId: string;
    businessUnit: string;
    department: string;
    account: string;
    project: string;
    startDate: string;
    endDate: string;
    status: string;
    currency: string;
    projectType: string;
    description: string;
    estimationHours: string;
    baseProject: string;
  }


  const formik = useFormik<FormValues>({
    onSubmit: async (values) => {
      console.log("val", values)
      setIsLoading(true);
      const matchingUnit = businessUnits.find((businessUnit) => businessUnit.unitname === values.businessUnit);
      if (matchingUnit) {
        values.businessUnit = matchingUnit.id;
      }
      const matchingDepartment = departments.find((department) => department.deptname === values.department);
      if (matchingDepartment) {
        values.department = matchingDepartment.id;
      }
      const matchingAccount = clients.find((account) => account.client_name === values.account);
      if (matchingAccount) {
        values.account = matchingAccount.id;
      }
      const matchingCurrency = currencies.find((currency) => currency.currencyname === values.currency);
      if (matchingCurrency) {
        values.currency = matchingCurrency.id;
      }
      const matchingBaseProject = baseProjects.find((baseProject) => baseProject.project_name === values.baseProject);
      if (matchingBaseProject) {
        values.baseProject = matchingBaseProject.id;
      }
      values.status = (values.status === 'In progress') ? 'In-progress' :
        (values.status === 'In Pipeline') ? 'In_Pipeline' :
          values.status;
      try {
        const response = await updateProjectDetail({
          projectId: values.projectId,
          businessUnit: values.businessUnit,

          department: values.department,
          account: values.account,
          project: values.project,
          startDate: values.startDate,
          endDate: values.endDate,
          status: values.status,
          currency: values.currency,
          projectType: values.projectType,
          description: values.description,
          baseProject: values.baseProject,
          estimationHours: values.estimationHours
        })  
        if(values.project != projectName || values.account != clientName){
          const jobStatus = {
            projectId: response.data.data.id,
            jobId: response?.data?.job_id,
            updated: true
          }
          localStorage.setItem('jobStatus', JSON.stringify(jobStatus));
          goToProgressVinePage("Project Details updated successfully; Google Groups are being updated.","warning", 3000);
        }
        else{
          removeAllToasts()
          addToast("Project details updated successfully", {id: 'ACCESS_DENIED', appearance: "success", autoDismiss: true});
        }
        flag.current = true;
        setSave(true);
        setIsEditing(false);
      }

      catch (error) {
        console.error("Error updating:", error);
      }

      // setIsLoading(false)
      return undefined;
    },

    initialValues: {
      projectId: "",
      businessUnit: "",
      department: "",
      account: "",
      project: "",
      startDate: "",
      endDate: "",
      status: "",
      currency: "",
      projectType: "",
      description: "",
      estimationHours: "",
      baseProject: ""
    },
    validate: (values) => {
      const errors: Partial<FormValues> = {};
      if (values.project) {
        if (values.project.length !== 4) {
            errors.project = "Must be 4 characters";
        } else {
            // Check if all characters are alphabets in uppercase
            if (!/^[A-Z]+$/.test(values.project)) {
                errors.project = "Must contain only uppercase alphabets";
            }

        }
    }
    else {
        errors.project = "Project is required"
    }
      if(!values.department){
        errors.department = "Department is required"
      }
      if(!values.businessUnit){
        errors.businessUnit = "Business Unit is required"
      }
      if(!values.account){
        errors.account = "Account is required"
      }
      if(!values.status){
        errors.status = "Status is required"
      }
      if(!values.projectType){
        errors.projectType = "Type is required"
        }

      return errors;
    },

  });
  useEffect(() => {
    if (isEditing) {
      setIsLoading(true);
      getBusinessUnits()
        .then((res) => {
          setBusinessUnits(res?.data)
        })
        .catch((e) => {
          if (!validateExpiredToken(e.message)) {
            navigate('/');
            window.location.reload();
          }
          console.log(e.message);
        });
      getCurrencies()
        .then((res) => {
          setcurrencies(res?.data)
        })
        .catch((e) => {
          console.log(e.message);
        });
      getClients()
        .then((res) => {
          setclients(res?.data)
        })
        .catch((e) => {
          console.log(e.message);
        });
      getDepartmentList({})
        .then((res) => {
          setdepartments(res?.data)
        })
        .catch((e) => {
          console.log(e.message);
        });
      getProjects()
        .then((res) => {
          setIsLoading(false);
          setbaseProjects(res?.data)
        })
        .catch((e) => {
          console.log(e.message);
        });

      console.log("API call")
    }
  }, [isEditing,navigate]);
  const [initialFormValues, setInitialFormValues] = useState<FormValues>(formik.values);

  useEffect(() => {
    (async() => {
      setIsAedLoading(true);
      getAedAccess({ projectId }).then((res) => {setAedAccess(res?.data?.aed_access);
      setIsAedLoading(false);
      });
    })()
  }, [projectId])

  useEffect(() => {
    if(Object.keys(aedAccess).length === 0) {
      return;
    }
    if (window.location.pathname.indexOf("project-roster-sheet") > -1) {
      setActiveTab(TABS.TRS)
    }
    else if (window.location.pathname.indexOf("project-members") > -1) {
      setActiveTab(TABS.PROJECTMEMBERS)
    } else if (window.location.pathname.indexOf("project-profile") > -1) {
      if (aedAccess?.view){
        setActiveTab(TABS.PROFILE)
      } else {
        const url = window.location.pathname;
        const segments = url.split('/');
        const lastSegment = segments[2];
        const encodedProjectId = window.btoa(projectId);
        const newUrl = `/projects/${lastSegment}/project-details?id=${encodedProjectId}`;
        window.history.pushState(null, '', newUrl);
      }
    }
    else if (window.location.pathname.indexOf("project-timesheets") > -1) {
      setActiveTab(TABS.TIMESHEETS)
    };
  }, [TABS.PROJECTMEMBERS, TABS.TRS, TABS.PROFILE, aedAccess, projectId, TABS.TIMESHEETS]);
  useEffect(() => {
    if (flag.current || save || projectDetailsReload) {
      flag.current = false;
      setIsLoading(true);

      getSelectedProjectDetails({ projectId })
        .then((res) => {
          const projectData = res?.data?.data[0]; // Assuming it's an array
          setprojectDetails(projectData);
          setEditAccess(res?.data?.edit_access);
          setTrsAccess(res?.data?.trs_access)
          const url = window.location.pathname;
          const segments = url.split('/');
          const lastSegment = segments[2];
          const projectNameFromDb = res?.data?.data[0]?.project_name.toLowerCase()
          if (projectNameFromDb !== segments[2]) {
            removeAllToasts()
            goToProgressVinePage("You don't have access to this project!","error", 3000);
          }

          formik.setValues({
            projectId: res?.data?.data[0]?.id || "",
            businessUnit: res?.data?.data[0]?.business_unit?.unitname || "",
            department: res?.data?.data[0]?.department?.deptname || "",
            account: res?.data?.data[0]?.tm_client?.client_name || "",
            project: res?.data?.data[0]?.project_name || "",
            startDate: res?.data?.data[0]?.start_date ? convertDate(res?.data?.data[0]?.start_date) : "",
            endDate: res?.data?.data[0]?.end_date ? convertDate(res?.data?.data[0]?.end_date) : "",
            status: res?.data?.data[0]?.project_status || "",
            currency: res?.data?.data[0]?.currency?.currencyname || "",
            projectType: res?.data?.data[0]?.project_type || "",
            description: res?.data?.data[0]?.description || "",
            estimationHours: res?.data?.data[0]?.estimated_hrs || "",
            baseProject: res?.data?.data[0]?.base_project || "",
          });
          setProjectName(res?.data?.data[0]?.project_name || "")
          setClientName(res?.data?.data[0]?.tm_client?.id || "")

          setInitialFormValues(formik.values);
          setInitialValue(true)

          setSave(false);
          setProjectDetailsReload(false);
          setIsLoading(false);
        })
        .catch((e) => {
          if (!validateExpiredToken(e.message)) {
            navigate('/');
            window.location.reload();
          }
          else {
            removeAllToasts()
            goToProgressVinePage("You don't have access to this project!","error", 3000)
          }
        })
        .finally(() => {
          setSave(false);
          setProjectDetailsReload(false);
          setIsLoading(false);
        });
    }
  }, [projectId, flag, formik, navigate, save, goToProgressVinePage, projectDetailsReload, removeAllToasts]);
  const formatDate = (dateString: string) => {
    const timestamp = new Date(dateString);
    const day = timestamp.getDate();
    const month = timestamp.toLocaleString('default', { month: 'short' }).slice(0, 3);
    const year = timestamp.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  function calculateMinDateForEndDate(startDate: string | number | Date) {
    if (startDate) {
      const minDate = new Date(startDate);
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    }
    return null;
  }
  function calculateMaxDateForStartDate(startDate: string | number | Date) {
    if (startDate) {
      const maxDate = new Date(startDate);
      maxDate.setDate(maxDate.getDate());
      return maxDate;
    }
    return null;
  }

  const [description, setDescription] = useState(formik.values.description);

  const handleInput = (event: { target: { value: any; }; }) => {
    const inputValue = event.target.value;

    // Truncate the input to 250 characters
    const truncatedInput = inputValue.substring(0, 250);

    setDescription(truncatedInput);
    handleDescriptionChange({ target: { value: truncatedInput } });
  };

  const checkFormDirtyRef = useRef<() => void>();

  const checkFormDirty = useCallback(() => {
    const isFormDirty = Object.keys(formik.values).some((key) => {
      const stringKey = key as keyof typeof formik.values;
      return formik.values[stringKey] !== formik.initialValues[stringKey];
    });

    if (isFormDirty) {
      if (isEditing && initialValue) {
        setInitialValue(false)
        return
      }
      if (isEditing && !initialValue) {
        setChanged(true)
      }
    }
  }, [formik, initialValue, isEditing]);

  checkFormDirtyRef.current = checkFormDirty;

  useEffect(() => {
    if (typeof checkFormDirtyRef.current === 'function') {

      checkFormDirtyRef.current();
    }
  }, [formik.values, formik.initialValues, isEditing]);

  const sortedProjectType =   ProjectType.sort((a: { value: string; }, b: { value: any; }) => a.value.localeCompare(b.value));     
  const handleUnitChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    const matchingUnit = businessUnits.find((businessUnit) => businessUnit.unitname === inputValue)?.id;
    formik.setFieldValue('businessUnit', inputValue);
    formik.handleChange(event);
    formik.setFieldValue('department', '');
    if(inputValue!=''){
    getDepartmentList({unitId: matchingUnit})
    .then((res) => {
      setdepartments(res?.data)
    })
    .catch((e) => {
      console.log(e.message);
    });
  } 
  else{
    setdepartments([])
  }
  }
  let currentUrl = new URL(window.location.href);
  const pathname = currentUrl?.pathname?.split('/');
  function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
  return (
    <PageWrapper title={projectMemberShipTitle}>
      <SubHeader >
                <SubHeaderLeft>
                    <Breadcrumb isToHome={false}
                        list={[
                            {
                              title: capitalizeFirstLetter(dashboardMenu.myjourney.subMenu.processvineListpage.text),
                               to: `/${dashboardMenu.myjourney.subMenu.processvineListpage.path}`,
                            },
                            {
                              title: capitalizeFirstLetter(pathname[3].split('-')[1]),
                              to: ``,
                              disabled: true,
                          },
                            {
                              title: pathname[2].toUpperCase(),
                                to: ``,
                                disabled: true,
                            }
                        ]}
                    />

                </SubHeaderLeft>

            </SubHeader>
      <Page container='fluid'>
        <div className='row h-100'>
          <div className='col-xxl-2 col-xl-2 col-lg-6'>
            <Card stretch>
              <CardHeader>
                <></>
              </CardHeader>
              <CardBody isScrollable>
                <div className='row g-3'>
                  <div className='col-12'>
                    <Button
                      color='info'
                      className='w-100 p-3'
                      isLight={TABS.PROJECTDETAILS !== activeTab}
                      onClick={() => {
                        const url = window.location.pathname;
                        const segments = url.split('/');
                        const lastSegment = segments[2];
                        const encodedProjectId = window.btoa(projectId);
                        const newUrl = `/projects/${lastSegment}/project-details?id=${encodedProjectId}`;
                        window.history.pushState(null, '', newUrl);
                        setProjectDetailsReload(true);
                        setIsEditing(false);
                        setActiveTab(TABS.PROJECTDETAILS);
                      }}
                    >
                      {TABS.PROJECTDETAILS}
                    </Button>
                  </div>
                  <div className='col-12'>
                    <Button

                      color='info'
                      className='w-100 p-3'
                      isLight={TABS.PROJECTMEMBERS !== activeTab}
                      onClick={() => {
                        const url = window.location.pathname;
                        const segments = url.split('/');
                        const lastSegment = segments[2];
                        const encodedProjectId = window.btoa(projectId);
                        const newUrl = `/projects/${lastSegment}/project-members/?id=${encodedProjectId}`;

                        // Use pushState to change the URL without hard refresh
                        window.history.pushState(null, '', newUrl);

                        // Optionally, trigger the setActiveTab function here
                        setActiveTab(TABS.PROJECTMEMBERS);
                      }}
                    >
                      {TABS.PROJECTMEMBERS}
                    </Button>
                  </div>
                  {/* <div className='col-12'>
                    <Button

                      color='info'
                      className='w-100 p-3'
                      isLight={TABS.TRS !== activeTab}
                      onClick={() => {
                        const url = window.location.pathname;
                        const segments = url.split('/');
                        const lastSegment = segments[2];
                        const encodedProjectId = window.btoa(projectId);
                        const newUrl = `/projects/${lastSegment}/project-roster-sheet/?id=${encodedProjectId}`;

                        // Use pushState to change the URL without hard refresh
                        window.history.pushState(null, '', newUrl);

                        // Optionally, trigger the setActiveTab function here
                        setActiveTab(TABS.TRS);
                      }}>
                      {TABS.TRS}
                    </Button>
                  </div> */}
                  
                   {trsAccess &&
                   <div className='col-12'>
                     <Button
 
                       color='info'
                       className='w-100 p-3'
                       isLight={TABS.TIMESHEETS !== activeTab}
                       onClick={() => {
                         const url = window.location.pathname;
                         const segments = url.split('/');
                         const lastSegment = segments[2];
                         const encodedProjectId = window.btoa(projectId);
                         const newUrl = `/projects/${lastSegment}/project-timesheets/?id=${encodedProjectId}`;
 
                         // Use pushState to change the URL without hard refresh
                         window.history.pushState(null, '', newUrl);
 
                         // Optionally, trigger the setActiveTab function here
                         setActiveTab(TABS.TIMESHEETS);
                       }}>
                       {TABS.TIMESHEETS}
                     </Button>
                     </div>
                     }
                   
                  <div className='col-12'>
                    {aedAccess?.view && <Button
                      color='info'
                      className='w-100 p-3'
                      isLight={TABS.PROFILE !== activeTab}
                      onClick={() => {
                        const url = window.location.pathname;
                        const segments = url.split('/');
                        const lastSegment = segments[2];
                        const encodedProjectId = window.btoa(projectId);
                        const newUrl = `/projects/${lastSegment}/project-profile?id=${encodedProjectId}`;
                        window.history.pushState(null, '', newUrl);
                        setActiveTab(TABS.PROFILE);
                      }}>
                      {TABS.PROFILE}
                    </Button>}
                  </div>

                  <div className='col-12 border-bottom' />
                  <div className='col-12 shadow-3d-container'>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <CardFooterLeft className='w-100'>
<></>
                </CardFooterLeft>
              </CardFooter>
            </Card>
          </div>
          <div className='col-xxl-10 col-xl-10 col-lg-6'>



          {TABS.PROJECTMEMBERS === activeTab && <ProjectMembershipDetails projectId={projectId} load={load} isLoading ={isLoading} />}
            {TABS.TRS === activeTab && <RosterTable projectId={projectId} load={load} />}
            {TABS.TIMESHEETS === activeTab && <TimeSheet projectId={projectId} load={load}  isLoading = {isLoading}/>}
            {TABS.PROJECTDETAILS === activeTab && (
              <Card stretch tag='form' noValidate >

                <CardBody isScrollable>


                  <Card>
                    <CardHeader>
                      <CardLabel>

                        <CardTitle>Details</CardTitle>

                      </CardLabel>
                      <CardActions>


                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            {editAccess && (
                              <div>
                                {isEditing ? (
                                  <Button
                                    color='info'
                                    className="btn btn-light-info"
                                    isLight
                                    onClick={() => {

                                      if (Object.keys(formik.errors).length === 0) {
                                        formik.handleSubmit();
                                        setIsEditing(false); // Set isEditing to false after saving
                                      } else {
                                        console.log('No changes or form has errors, cannot submit.');
                                      }
                                    }}
                                    isDisable={!(changed)}

                                  >
                                    Update
                                  </Button>
                                ) : null}
                                {!isEditing && (
                                  <Button
                                    color='info'
                                    className="btn btn-light-info"
                                    isLight
                                    onClick={() => {
                                      setIsEditing(true); // Set isEditing to true when editing
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>



                          {isEditing && (
                            <div style={{ marginLeft: '10px' }}> {/* Adjust the margin as needed */}
                              <Button
                                icon="close"
                                className="btn btn-light-danger"

                                onClick={() => {
                                  flag.current = true;
                                  setIsEditing(false);
                                  setShowMessage(false)

                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          )}
                        </div>




                      </CardActions>
                    </CardHeader>
                    <CardBody>
                      <div className='row g-4'>
                        {isEditing ? (<div className="col-4">
                          <FormGroup id="account" labelClassName="label-font-color" label="Account" isRequired={true}>
                          <Select
                              className="processvine-form-font-weight"
                              ariaLabel="Account"
                              placeholder= "Select Account"
                              onChange={formik.handleChange}
                              value={formik.values.account}
                              list={[
                                { value: '', text: 'Please Select' },
                                ...clients.map((client) => ({
                                  value: client.client_name,
                                  text: client.client_name,
                                })),
                            ]}
                            onBlur={formik.handleBlur}
                            isValid={formik.isValid}
                            isTouched={true}
                            invalidFeedback={
                              formik.errors.account}
                            validFeedbackNeeded={true}
                            />
                          </FormGroup>
                        </div>) : (
                          <div className='col-xl-4'>
                            <FormGroup
                              label='Account'
                              labelClassName="label-font-color"
                              isFloating>
                              <Input
                                className="processvine-form-font-weight input-box-shadow-unset"
                                value={save ? "" : formik.values.account}
                                readOnly
                              />
                            </FormGroup>
                          </div>
                        )}
                        {isEditing ? (
                          <div className='col-xl-4'>
                            <FormGroup
                              id='project'
                              label='Project'
                              labelClassName="label-font-color"
                              isRequired={true}
                            >
                              <Input
                                className="processvine-form-font-weight"
                                autoComplete='additional-name'
                                onChange={formik.handleChange}
                                value={formik.values.project}
                                onBlur={formik.handleBlur}
                                isValid={formik.isValid && formik.touched.project}
                                isTouched={formik.touched.project}
                                invalidFeedback={
                                  formik.errors.project && formik.touched.project
                                    ? formik.errors.project
                                    : ''
                                }
                                validFeedbackNeeded={true}
                              />
                            </FormGroup>
                          </div>) : (<div className='col-xl-4'>
                            <FormGroup
                              id='project'
                              label='Project'
                              labelClassName="label-font-color"
                              isFloating>
                              <Input
                                className="processvine-form-font-weight input-box-shadow-unset"
                                onChange={formik.handleChange}
                                value={save ? "" : formik.values.project}
                                readOnly
                              />
                            </FormGroup>
                          </div>)}
                        {isEditing ? (<div className="col-4">
                          <FormGroup id="status" label="Status" labelClassName="label-font-color" isRequired={true}>
                          <Select
                              className="processvine-form-font-weight"
                              ariaLabel="status"
                              placeholder="Select Status"
                              onChange={formik.handleChange}
                              value={
                                ProjectStatusOptions.find(
                                  (status: { value: string; }) =>
                                    status.value.toLowerCase() === formik.values.status.toLowerCase()
                                )?.value || formik.values.status
                              }
                              
                              list={[
                                { value: '', text: 'Please Select' },
                                ...ProjectStatusOptions.map((status: { key: any; value: any; })=> ({
                                  value: status.value,
                                  text: status.key,
                                })),
                            ]}
                            onBlur={formik.handleBlur}
                            isValid={formik.isValid}
                            isTouched={true}
                            invalidFeedback={
                              formik.errors.status}
                            validFeedbackNeeded={true}
                            />
                          </FormGroup>
                        </div>) : (
                          <div className='col-xl-4'>
                            <FormGroup
                              labelClassName="label-font-color"
                              label='Status'
                              isFloating>
                              <Input
                                className="processvine-form-font-weight input-box-shadow-unset"
                                value={
                                  save
                                    ? ""
                                    : ProjectStatusOptions.find((status: { value: string; }) => status.value.toLowerCase() == formik.values.status.toLowerCase())?.key
                                }
                                readOnly
                              />
                            </FormGroup>
                          </div>)}
                        {isEditing ? (<div className="col-4">
                          <FormGroup id="businessUnit" labelClassName="label-font-color" label="Business Unit" isRequired={true}>
                          <Select
                              className="processvine-form-font-weight"
                              ariaLabel="businessUnit"
                              placeholder="Select Business Unit"
                              onChange={handleUnitChange}
                              value={formik.values.businessUnit}
                              list={[
                                { value: '', text: 'Please Select' },
                                ...businessUnits.map((unit) => ({
                                    value: unit.unitname,
                                    text: unit.unitname,
                                })),
                            ]}
                            onBlur={formik.handleBlur}
                            isValid={formik.isValid}
                            isTouched={true}
                            invalidFeedback={
                              formik.errors.businessUnit}
                            validFeedbackNeeded={true}
                            />
                          </FormGroup>
                        </div>) : (<div className='col-xl-4'>
                          <FormGroup
                            labelClassName="label-font-color"
                            label='Business Unit'
                            isFloating>
                            <Input
                              className="processvine-form-font-weight input-box-shadow-unset"
                              readOnly
                              value={save ? "" : formik.values.businessUnit}
                            />
                          </FormGroup>
                        </div>)}
                        {isEditing ? (<div className="col-4">
                          
                        <FormGroup id="department" labelClassName="label-font-color"  label='Department' isRequired={true}>
                        <Select
                                        className="processvine-form-font-weight"
                                        ariaLabel="businessUnit"
                                        placeholder="Select Department"
                                        onChange={formik.handleChange}
                                        value={formik.values.department}
                                        list={[
                                            { value: '', text: 'Please Select' },
                                            ...departments.map((department) => ({
                                                value: department.deptname,
                                                text: department.deptname,
                                            })),
                                        ]}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.isValid}
                                        isTouched={true}
                                        invalidFeedback={
                                          formik.errors.department
                                            ? formik.errors.department
                                            : ''
                                        }
                                        validFeedbackNeeded={true}

                                    />
                                </FormGroup>
                        </div>) : (<div className='col-xl-4'>
                          <FormGroup
                            label='Department'
                            labelClassName="label-font-color"
                            isFloating>
                            <Input
                              className="processvine-form-font-weight input-box-shadow-unset"
                              value={save ? "" : formik.values.department}
                              readOnly
                            />
                          </FormGroup>
                        </div>)}

                        {isEditing ? (<div className="col-4">
                          <FormGroup id="projectType" label="Type" labelClassName="label-font-color" isRequired={true}>
                          <Select
                              className="processvine-form-font-weight"
                              ariaLabel="projectType"
                              placeholder= "Select Type"
                              onChange={formik.handleChange}
                              value={formik.values.projectType}
                              list={[
                                { value: '', text: 'Please Select' },
                                ...sortedProjectType.map((project: { key: any; value: any; }) => ({
                                  value: project.key,
                                  text: project.value,
                                })),
                            ]}
                            onBlur={formik.handleBlur}
                            isValid={formik.isValid}
                            isTouched={true}
                            invalidFeedback={
                              formik.errors.projectType}
                            validFeedbackNeeded={true}
                            />
                          </FormGroup>
                        </div>) : (
                          <div className='col-xl-4'>
                            <FormGroup
                              labelClassName="label-font-color"
                              label='Type'
                              isFloating>
                              <Input
                                className="processvine-form-font-weight input-box-shadow-unset"
                                value={save ? "" : formik.values.projectType}
                                readOnly
                              />
                            </FormGroup>
                          </div>)}
                        {isEditing ? (
                          <div className='col-xl-4'>
                            <FormGroup id='startDate' label='Start Date' labelClassName="label-font-color" className="datepicker-container">
                              <DatePicker
                                showIcon
                                className="processvine-form-font-weight form-control processvine-project-date-format"
                                selected={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                onChange={(date: any) => {
                                  console.log("Received date:", date);
                                  formik.setFieldValue("startDate", date);
                                }}
                                maxDate={calculateMaxDateForStartDate(formik.values.endDate)}
                                dateFormat="dd-MMM-yyyy"
                                placeholderText="Click to select a date"
                                icon={<DatePickerIcon />}
                              />
                            </FormGroup>
                          </div>
                        ) : (
                          <div className='col-xl-4'>
                            <FormGroup labelClassName="label-font-color" label='Start Date' isFloating>
                              <Input
                                className="processvine-form-font-weight input-box-shadow-unset input-width"
                                value={save ? "" : formik.values.startDate ? formatDate(formik.values.startDate) : ''}
                                readOnly
                              />
                            </FormGroup>
                          </div>
                        )}



                        {isEditing ? (
                          <div className='col-xl-4'>
                            <FormGroup
                              id='endDate'
                              label='End Date'
                              labelClassName="label-font-color"
                              className="datepicker-container"
                            >
                              <DatePicker
                                showIcon

                                className="processvine-form-font-weight form-control processvine-project-date-format"
                                selected={formik.values.endDate ? new Date(formik.values.endDate) : null}
                                minDate={calculateMinDateForEndDate(formik.values.startDate)}
                                onChange={(date: any) => {
                                  console.log("Received date:", date);
                                  formik.setFieldValue("endDate", date);
                                }}

                                icon={<DatePickerIcon />}
                                dateFormat="dd-MMM-yyyy"
                                placeholderText="Click to select a date"
                              />

                            </FormGroup>
                          </div>) : (<div className='col-xl-4'>
                            <FormGroup
                              labelClassName="label-font-color"
                              label='End Date'
                              isFloating>
                              <Input
                                className="processvine-form-font-weight input-box-shadow-unset"
                                value={save ? "" : formik.values.endDate ? formatDate(formik.values.endDate) : ''}
                                readOnly
                              />
                            </FormGroup>
                          </div>)}
                        {isEditing && <div className="col-4">
                          <FormGroup id="baseProject" label="Base Project" labelClassName="label-font-color">
                          <Select
                              className="processvine-form-font-weight"
                              ariaLabel="baseProject"
                              placeholder="Select Base Project"
                              onChange={formik.handleChange}
                              value={formik.values.baseProject}
                              list={[
                                { value: "", text: "Please Select" },
                                ...baseProjects.map((project) => ({
                                  value: project.id,
                                  text: project.project_name,
                                })),
                              ]}
                            />
                          </FormGroup>
                        </div>}







                        {isEditing && <div className='col-xl-4'>
                          <FormGroup
                            id='estimationHours'
                            label='Estimation Hours'
                            labelClassName="label-font-color"
                          >
                            <Input
                              className="processvine-form-font-weight"
                              type='number'
                              placeholder='Enter Estimation Hours'
                              onChange={(e: any) => {
                                // Ensure the entered value is not negative
                                const inputValue = e.target.value;
                                if (inputValue >= 0) {
                                    formik.handleChange(e);
                                }
                            }}
                              value={formik.values.estimationHours}

                            />
                          </FormGroup>
                        </div>}
                        {isEditing ? (
                          <div className="col-12">
                            <FormGroup label={`Description (Note: Max 500 Characters allowed)`} labelClassName="label-font-color" id='description'>

                              <Textarea
                                rows={5}
                                onInput={handleInput}
                                id="Description"
                                maxLength={500}
                                onChange={handleDescriptionChange}
                                value={formik.values.description}
                                className="processvine-form-font-weight
"
                              />


                            </FormGroup>
                            {showMessage && (
                              <p className='description-validation'>
                                Exceeded the 500 Characters limit.
                              </p>
                            )}
                          </div>) : (<div className="col-12">
                            <FormGroup label="Description" labelClassName="label-font-color">
                              <Textarea
                                className="processvine-form-font-weight input-box-shadow-unset"
                                rows={5}
                                id="Description"
                                readOnly

                                value={formik.values.description}
                              />
                            </FormGroup>
                          </div>)}

                      </div>
                    </CardBody>
                    {(isLoading || load || isAedLoading) && (
                      <div className="processvine-overlay-box">
                        <Spinner
                          className="text-center processvine-no-projects processvine-spinner"
                          inButton
                        />
                      </div>
                    )}
                  </Card>
                </CardBody>

              </Card>
            )}
            {TABS.PROFILE === activeTab && <AedBase projectId={projectId} aedAccess={aedAccess}/>}
          </div>
        </div>
      </Page>
    </PageWrapper>

  );
};

export default MainProjectMemberPage;