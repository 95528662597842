import UserImage2 from '../../assets/img/wanna/wanna2.png';
import UserImage2Webp from '../../assets/img/wanna/wanna2.webp';
import UserImage3 from '../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../assets/img/wanna/wanna3.webp';
import UserImage4 from '../../assets/img/wanna/wanna4.png';
import UserImage4Webp from '../../assets/img/wanna/wanna4.webp';
import UserImage5 from '../../assets/img/wanna/wanna5.png';
import UserImage5Webp from '../../assets/img/wanna/wanna5.webp';
import UserImage6 from '../../assets/img/wanna/wanna6.png';
import UserImage6Webp from '../../assets/img/wanna/wanna6.webp';
import UserImage7 from '../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../assets/img/wanna/wanna7.webp';
import SERVICES, { IServiceProps } from './serviceDummyData';

import User7Landing from '../../assets/img/wanna/landing1.png';
import { TColor } from '../../type/color';
import axios from './AxiosService';
import Cookies from 'js-cookie';
import IMainKpi from '../../model/MainKPI';
import IProcessVineLogs from '../../model/IProcessVineLogs';
import { getValueFromToken, getGroupFromCookie, hrGroups, prvnGroups } from '../../App/Constants';
import { IProjectsScore } from '../../model/IProjectsScore';
import { serviceURL, serviceCGPURL, railsAPI, agvnURL, agvnAccessKey } from '../../types';
import { IEmployeeCGP } from '../../model/EmployeeCGP';
import { IEmpTitlePosition } from '../../model/EmpTitlePosition';

import { IRiskDetails } from '../../model/Risk';
import { IRiskReview } from '../../rkvn/Review';

// Used multiple times.
const HR = 'hr';
type GetTimeEntriesResponse = {
    status?: number,
    message?: string,
    type?: string,
    data?: {
        time_entries?: Record<string, number>,
    },
    error?: string,
};
export const ManagementKpi: {
    id: number;
    title: string;
    kpi: {
        id: number;
        status: string;
        score: string;
        info: string;
    }[];
}[] = [
    {
        id: 1,
        title: 'Process Doc',
        kpi: [
            {
                id: 1,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 1,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 1,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 2,
        title: 'Schedule (Timely Delivery)',
        kpi: [
            {
                id: 2,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 2,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 2,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 3,
        title: 'Risks',
        kpi: [
            {
                id: 3,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 3,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 3,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 4,
        title: 'CGP/SGA',
        kpi: [
            {
                id: 4,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 4,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 4,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 5,
        title: 'Effort Management',
        kpi: [
            {
                id: 5,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 5,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 5,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 6,
        title: 'Sourcing Management',
        kpi: [
            {
                id: 6,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 6,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 6,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 7,
        title: 'Retrospective Management',
        kpi: [
            {
                id: 7,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 7,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 7,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 8,
        title: 'Intra Team',
        kpi: [
            {
                id: 8,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 8,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 8,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
    {
        id: 9,
        title: 'Inter Departmental',
        kpi: [
            {
                id: 9,
                status: 'Rejected',
                score: '50%',
                info: '- Raks Raja on 11-Feb-2022',
            },
            {
                id: 9,
                status: 'Approved',
                score: '60%',
                info: '- Fareen on 12-Feb-2022',
            },
            {
                id: 9,
                status: 'Requested',
                score: '70%',
                info: '- Sundar on 15-Feb-2022',
            },
        ],
    },
];

export interface IUserProps {
    id: string;
    username: string;
    name: string;
    surname: string;
    position: string;
    src: string;
    srcSet: string;
    isOnline: boolean;
    isReply?: boolean;
    color: TColor;
    fullImage?: string;
    services?: IServiceProps[];
}

export interface IPetProps {
    id: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    telephone: string;
    pets: any[];
    ownerId: string;
    visits?: any[];
}

const grace: IUserProps = {
    id: '2',
    username: 'grace',
    name: 'Grace',
    surname: 'Buckland',
    position: 'Staff',
    src: UserImage2,
    srcSet: UserImage2Webp,
    isOnline: true,
    color: 'warning',
    services: [SERVICES.SNOWBOARDING, SERVICES.ICE_SKATING, SERVICES.KITE_SURFING],
};

const jane: IUserProps = {
    id: '3',
    username: 'jane',
    name: 'Jane',
    surname: 'Lee',
    position: 'Staff',
    src: UserImage3,
    srcSet: UserImage3Webp,
    isOnline: true,
    color: 'secondary',
    services: [SERVICES.YOGA, SERVICES.HANDBALL, SERVICES.CRICKET],
};

const ryan: IUserProps = {
    id: '4',
    username: 'ryan',
    name: 'Ryan',
    surname: 'McGrath',
    position: 'Worker',
    src: UserImage4,
    srcSet: UserImage4Webp,
    isOnline: false,
    color: 'info',
    services: [SERVICES.HIKING, SERVICES.FOOTBALL, SERVICES.HANDBALL],
};

const ella: IUserProps = {
    id: '5',
    username: 'ella',
    name: 'Ella',
    surname: 'Oliver',
    position: 'Worker',
    src: UserImage5,
    srcSet: UserImage5Webp,
    isOnline: false,
    color: 'success',
    services: [SERVICES.ICE_SKATING, SERVICES.TENNIS, SERVICES.SNOWBOARDING, SERVICES.YOGA],
};

const chloe: IUserProps = {
    id: '6',
    username: 'chloe',
    name: 'Chloe',
    surname: 'Walker',
    position: 'Staff',
    src: UserImage6,
    srcSet: UserImage6Webp,
    isOnline: true,
    color: 'warning',
    services: [SERVICES.VOLLEYBALL, SERVICES.CRICKET],
};

const sam: IUserProps = {
    id: '7',
    username: 'sam',
    name: 'Sam',
    surname: 'Roberts',
    position: 'Worker',
    src: UserImage7,
    srcSet: UserImage7Webp,
    isOnline: false,
    color: 'danger',
    fullImage: User7Landing,
};

const USERS: { [key: string]: IUserProps } = {
    JOHN: grace,
    GRACE: grace,
    JANE: jane,
    RYAN: ryan,
    ELLA: ella,
    CHLOE: chloe,
    SAM: sam,
};


export async function getProjectList() {
    let emailAddress = getValueFromToken('email');
    // let url = new URL(window.location.href);
    // const pathname = url?.pathname?.split('/');
    // let kpitype = pathname[2];
    const response = await axios.get(`${serviceURL}/api/progressvine/project-lists`, {
        params: {
            email: emailAddress,
            // kpiType : kpitype
        },
    });
    return response;
}

export async function getEmployeeDetails() {
    const groupResponse: any = getGroupFromCookie();
    let emailAddress = getValueFromToken('email');
    let url = new URL(window.location.href);
    const pathname = url?.pathname?.split('/');
    const isMyTeam = pathname[pathname.length - 2] === HR;
    const isHR =
        (groupResponse.length > 0 &&
            groupResponse?.some((o1: string) => hrGroups?.some((o2: string) => o1 === o2))) ||
        emailAddress === 'aatest.user@sysvine.com';
    const response = await axios.get(`${serviceURL}/api/service-confirmation/employee-lists`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            isMyTeam: !isMyTeam,
        },
        withCredentials: true,
    });
    return response;
}

export async function employeeSearchFilter(params: any) {
    const groupResponse: any = getGroupFromCookie();
    let emailAddress = getValueFromToken('email');
    let url = new URL(window.location.href);
    const pathname = url?.pathname?.split('/');
    const isMyTeam = pathname[pathname.length - 2] === HR;
    const isHR =
        (groupResponse.length > 0 &&
            groupResponse?.some((o1: string) => hrGroups?.some((o2: string) => o1 === o2))) ||
        emailAddress === 'aatest.user@sysvine.com';
    const response = await axios.get(`${serviceURL}/api/service-confirmation/employee-filter`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            searchInput: params?.employee,
            isMyTeam: !isMyTeam,
        },
        withCredentials: true,
    });
    return response;
}

export async function projectSearchFilter(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.get(`${serviceURL}/api/progressvine/project-filter`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            searchInput: params?.project,
            projectStatus: params?.projectStatus,
            projectType: params?.projectType,
            email: emailAddress,
        },
        withCredentials: true,
    });
    return response;
}

export async function employeeDetails(params: any) {
    const groupResponse: any = getGroupFromCookie();
    let emailAddress = getValueFromToken('email');
    const isHR =
        (groupResponse.length > 0 &&
            groupResponse?.some((o1: string) => hrGroups?.some((o2: string) => o1 === o2))) ||
        emailAddress === 'aatest.user@sysvine.com';
    let url = new URL(window.location.href);
    const pathname = url?.pathname?.split('/');
    const isMyProbations = pathname[pathname.length - 1] === 'myprobation';
    const response = await axios.get(`${serviceURL}/api/service-confirmation/employee-details`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            userId: params.userId,
        },
        withCredentials: true,
    });
    return response;
}

export async function getEmployeeLowDetails(params: any) {
    if (params.quarter === 'Jan - Mar') params.quarter = 'Q1';
    if (params.quarter === 'Apr - Jun') params.quarter = 'Q2';
    if (params.quarter === 'Jul - Sep') params.quarter = 'Q3';
    if (params.quarter === 'Oct - Dec') params.quarter = 'Q4';
    const response = await axios.get(
        `${serviceURL}/api/service-confirmation/employee-low-details`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
            params: {
                userId: params.userId,
                quarter: params.quarter,
                year: params.year,
            },
        },
    );
    return response;
}
export async function getEmployeeSCDetails(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.get(`${serviceURL}/api/service-confirmation/employee-sc-details`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            userId: params.userId,
        },
        withCredentials: true,
    });
    return response;
}
export async function getLoggedInUserId() {
    let emailAddress = getValueFromToken('email');
    const response = await axios.get(`${serviceURL}/api/service-confirmation/get-loggedin-userid`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        withCredentials: true,
    });
    return response;
}
export async function submitScStatus(params: any) {
    let emailAddress = getValueFromToken('email');
    let empStage = 0;
    let empStatusName = '';
    let empStatusId = 0;
    if (
        params.ScStatus === 'SC Initiated' ||
        params.ScStatus.includes('Requested') ||
        params.ScStatus.includes('Approved')
    ) {
        empStatusName = 'Probationary SC Review';
        empStatusId = 14;
    } else if (params.ScStatus === 'Extended') {
        empStatusName = 'Probationary SC Extended';
        empStatusId = 16; //SC Extended
    } else if (params.ScStatus === 'Confirmed') {
        empStatusName = 'Permanent';
        empStatusId = 3;
    } else if (params.ScStatus === 'Terminated') {
        empStage = 0; //SC Finalized
        empStatusName = 'Probationary SC Failed';
        empStatusId = 15;
    }
    const response = await axios.get(
        `${serviceURL}/api/service-confirmation/submit-service-confirmation-details`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
            params: {
                userId: params.userId,
                status: params.ScStatus,
                comments: params.ScDescription,
                empStage: empStage,
                extendedProbationDate: params.extendedProbationEndDate,
                empStatusId: empStatusId,
                empStatusName: empStatusName,
                nextStep: params.nextStep,
                empName: params.empName,
                loggedInUserName: params.loggedInUserName,
                subject: params.subject,
                emailBody: params.emailBody,
                empStageName: params.empStageName,
                emailGreeting: params.emailGreeting,
                probationEndDate: params.probationEndDate,
                leadsRequest: params.leadsRequest,
                reviewedOn: params.reviewedOn,
                probationConfirmedStartDate: params.probationConfirmedStartDate,
                isQE: params.isQE || false,
            },
            withCredentials: true,
        },
    );
    return response;
}
export async function updateScStatus(params: any) {
    let emailAddress = getValueFromToken('email');
    let empStage = 1;
    if (
        params.ScStatus === 'SC Initiated' ||
        params.ScStatus.includes('Requested') ||
        params.ScStatus.includes('Approved')
    ) {
        empStage = 5; //Under Review
    } else if (params.ScStatus === 'Extended') {
        empStage = 1; //SC Extended
    } else if (params.ScStatus === 'Confirmed') {
        empStage = 1; //SC Finalized
    } else if (params.ScStatus === 'Terminated') {
        empStage = 0; //SC Finalized
    }
    const response = await axios.get(
        `${serviceURL}/api/service-confirmation/update-service-confirmation-details`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
            params: {
                userId: params.userId,
                comments: params.ScDescription,
                empStage: empStage,
            },
            withCredentials: true,
        },
    );
    return response;
}

export function getUserDataWithUsername(username: string) {
    // @ts-ignore
    return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}

export function getUserDataWithId(id?: string): IUserProps {
    // @ts-ignore
    return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}
export async function getRiskDropDown(type: any) {
	const response = await axios.get(`${serviceCGPURL}/api/rkvn/${type}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getRiskDetails(id: number, value: any) {
	const response = await axios.get(`${serviceCGPURL}/api/rkvn/Risks/${id}/${value}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getRiskMgmtReview(id: number, type: string) {
	const email: string = getValueFromToken('email');
	const response = await axios.get(`${serviceCGPURL}/api/rkvn/MgmtReview/${id}/${type}/${email}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getRiskAuditLog(id: any) {
	const response = await axios.get(`${serviceCGPURL}/api/rkvn/Audit/${id}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getRiskDashboard(admin: string, groups: string) {
	if(admin == "False"){
		const response = await axios.get(`${serviceCGPURL}/api/rkvn/Risks/0/${groups}`, {
			headers: {
				Authorization: Cookies.get('idToken'),
			},
		});
		return response;
	}
	else {
		const response = await axios.get(`${serviceCGPURL}/api/rkvn/Risks/${admin}`, {
				headers: {
					Authorization: Cookies.get('idToken'),
				},
			});
		return response;
	}
}
export async function insertRisk(params: IRiskDetails | undefined, url: string) {
	const response = await axios.post(url, params, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function updateRisk(params: IRiskDetails | undefined, url: string) {
	const response = await axios.put(url, params, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function riskCRUD(params: IRiskDetails | undefined, type: string) {
	let name:string = getValueFromToken('name');
	name = JSON.stringify(name);
	let url = `${serviceCGPURL}/api/rkvn/Risks/${name}`;
	if (type == 'UPDATE') {
		const Id = params?.Id;
		url = `${serviceCGPURL}/api/rkvn/Risks/${Id}/${name}`;
		const response = await updateRisk(params, url);
		return response;
	} else {
		const response = await insertRisk(params, url);
		return response;
	}
}
export async function insertRiskReview(params: IRiskReview | undefined, url: string) {
	const response = await axios.post<IRiskReview>(url, params, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function updateRiskReview(params: IRiskReview | undefined, url: string) {
	const response = await axios.put<IRiskReview>(url, params, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function riskCRUDReview(params: IRiskReview | undefined, type: string) {
	let name:string = getValueFromToken('name');
	name = JSON.stringify(name);
	let url = `${serviceCGPURL}/api/rkvn/MgmtReview/${name}`;
	if (type == 'UPDATE') {
		const Id = params?.Id;
		url = `${serviceCGPURL}/api/rkvn/MgmtReview/${Id}/${name}`;
		const response = await updateRiskReview(params, url);
		return response;
	} else {
		const response = await insertRiskReview(params, url);
		return response;
	}
}
export async function getLoggedInUserInfo() {
    const emailAddress = getValueFromToken('email');
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employee/0/${emailAddress}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getskilldetails(titleid: number, positionid: number, empid: number) {
    const response = await axios.get(
        `${serviceCGPURL}/api/cgp/skill/NA/${titleid}/${positionid}/${empid}`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
        },
    );
    return response;
}

export async function getEmpCGPdetails(titleid: number, positionid: number, empid: number, cgpId: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employeecgp/${titleid}/${positionid}/${empid}/${cgpId}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function addEmployeeCGPSkills(titleid: number, positionid: number, empid: number) {
    const response = await axios.post(`${serviceCGPURL}/api/cgp/employeecgp/${titleid}/${positionid}/${empid}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function EmployeeCGPCRUDList(params: IEmployeeCGP | undefined, type: string) {
    const url = `${serviceCGPURL}/api/cgp/employeecgp`;
    if (type == 'UPDATE') {
        const response = await updateEmployeeCGPList(params, url);
        return response;
    } else {
        const response = await insertEmployeeCGPList(params, url);
        return response;
    }
}

export async function insertEmployeeCGPList(params: IEmployeeCGP | undefined, url: string) {
    const response = await axios.post<IEmployeeCGP>(url, params, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function updateEmployeeCGPList(params: IEmployeeCGP | undefined, url: string) {
    const response = await axios.put<IEmployeeCGP>(url, params, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getSkillViewdetails(empcgpid: number, cggID: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employeecgp/${empcgpid}/${cggID}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getcgpHistorydetails(empcgpid: number, cggID: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employeecgphistory/${empcgpid}/${cggID}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getTeamdetails(reportingmangerid: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employee/${reportingmangerid}/NA`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            type: "CGP",
        }
    });
    return response;
}

export async function getEmpHistorydetails(empid: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employeecgp/${empid}/0`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getProjectMemberDetails(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.get(`${railsAPI}/api/v1/project_members`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            project_id: params.projectId,
            email: emailAddress,
            project: params.projectName,
            requisition: true,
        },
    });
    return response;
}

export async function getDepartmentList(params: any = {}) {
    const response = await axios.get(`${railsAPI}/api/v1/project_members/department_list`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            unit_id: params.unitId,

        }
    });
    return response;
}
export async function getSysvineRoles() {
    const response = await axios.get(`${railsAPI}/api/v1/project_members/sysvine_roles`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getClientRoles() {
    const response = await axios.get(`${railsAPI}/api/v1/project_members/client_roles`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getBusinessUnits() {
    const response = await axios.get(`${railsAPI}/api/v1/projects/business_units`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getCurrencies() {
    const response = await axios.get(`${railsAPI}/api/v1/projects/currencies`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getClients() {
    const response = await axios.get(`${railsAPI}/api/v1/projects/clients`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getStatuses() {
    const response = await axios.get(`${railsAPI}/api/v1/project_members/statuses_list`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getEmployees(params: any) {
    const response = await axios.get(`${railsAPI}/api/v1/project_members/employees_list`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            project_id: params.projectId,
            department_id: params.department_id,
        },
    });
    return response;
}

export async function updateProjectDetail(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.patch(`${railsAPI}/api/v1/projects/edit`, {
        tm_project: {
            project_id: params.projectId,
            unit_id: params.businessUnit,
            department_id: params.department,
            client_id: params.account,
            project_name: params.project,
            start_date: params.startDate,
            end_date: params.endDate,
            project_status: params.status,
            currency_id: params.currency,
            project_type: params.projectType,
            description: params.description,
            estimated_hrs: params.estimationHours,
            base_project: params.baseProject,
            aed_description: params.aedDescription,
            aed_arch_json: params.aedArchComp,
            aed_technologies_json: params.aedTechnologies,
            aed_urls_json: params.aedUrls,
            aed_team_json: params.aedTeamJson,
        }
    }, {
      headers: {
        Authorization: Cookies.get('idToken'),
      }
    });
    return response;
  }

export async function getAedScreenshots(projectId: any) {
  const response = await axios.get(`${railsAPI}/api/v1/projects/${projectId}/screenshots`, {
    headers: {
      Authorization: Cookies.get('idToken'),
      'Content-Type': 'multipart/form-data'
    },
  });
  return response;
}

export async function removeAedScreenshot(params: any) {
    const { projectId, imagePath, screenshotId } = params;
    const response = await axios.delete(`${railsAPI}/api/v1/projects/${projectId}/screenshots/${screenshotId}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
            'Content-Type': 'multipart/form-data'
        },
        params: {
            tm_project_screenshots: {
                image_path: imagePath
            },
        }
    });
    return response;
}

export async function saveAedScreenshot(params: any) {
    const { projectId } = params;
    const response = await axios.post(`${railsAPI}/api/v1/projects/${projectId}/screenshots`, {
        tm_project_screenshots: {
            screenshots: params.screenshots
        },
    }, {
        headers: {
            Authorization: Cookies.get('idToken'),
            'Content-Type': !params.screenshots?.name ? 'application/json' : 'multipart/form-data'
        }
    });
    return response;
}

export async function updateAedScreenshot(params: any) {
    const { projectId, aedScreenshots } = params;
    const response = await axios.patch(`${railsAPI}/api/v1/projects/${projectId}/screenshots`, {
        headers: {
            Authorization: Cookies.get('idToken'),
            'Content-Type': 'multipart/form-data'
        },
        tm_project_screenshots: {
            aed_scrnshot_json: aedScreenshots
        },
    });
    return response;
}

export async function getProjects() {
    const response = await axios.get(`${railsAPI}/api/v1/projects/projects_list`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getSelectedProjectDetails(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.get(`${railsAPI}/api/v1/projects/project_details`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            project_id: params.projectId,
            email: emailAddress,
        },
    });
    return response;
}
export async function getAedAccess(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.get(`${railsAPI}/api/v1/projects/aed_access`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: { 
            tm_project: {
            project_id: params.projectId,
            email: emailAddress,
            }
        },
    });
    return response;
}
export async function getjobTitle(titleid: number | undefined, showAll: number = 0) {
	const response = await axios.get(`${serviceCGPURL}/api/cgp/title/${titleid}/NA?showAll=${showAll}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getposition() {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/position`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getstatus(type: string) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/status`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            type: type
        }
    });
    return response;
}

export async function getTechnologies() {
    try {
      const response = await axios.get(`${railsAPI}/api/v1/technologies`,
            {
                headers: {
                Authorization: Cookies.get('idToken'),
                }
            });
      return response.data;
    } catch (error) {
      console.error("Error fetching technologies:", error);
      throw error;
    }
    
}

export async function getEmployeeTitlePositions(empid: number | undefined) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/EmpTitlePosition/${empid}/0`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getEmpTitlePostionById(id: string | undefined) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/emptitleposition/0/${id}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function checkEmpTitlePosition(
    empId: number | undefined,
    titleId: number | undefined,
    positionId: number | undefined,
) {
    const response = await axios.get(
        `${serviceCGPURL}/api/cgp/emptitleposition/${empId}/${titleId}/${positionId}`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
        },
    );
    return response;
}
export async function SecondaryCRUDList(params: IEmpTitlePosition | undefined, type: string) {
    const url = `${serviceCGPURL}/api/cgp/emptitleposition`;
    if (type == 'UPDATE') {
        const response = await updateNewPostion(params, url);
        return response;
    } else {
        const response = await insertNewPostion(params, url);
        return response;
    }
}

export async function insertNewPostion(params: IEmpTitlePosition | undefined, url: string) {
    const response = await axios.post(url, params, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function updateNewPostion(params: IEmpTitlePosition | undefined, url: string) {
    const response = await axios.put(url, params, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getEmpTitlePostionStatus(
    status: String | undefined,
    type: String | undefined,
) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/status/${status}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            type: type,
        },
    });
    return response;
}
export async function deleteNewPostion(id: number, comments: string | undefined) {
    const response = await axios.delete(`${serviceCGPURL}/api/cgp/emptitleposition/${id}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            comments: comments,
        },
    });
    return response;
}
export async function getHrEmployeeDetails(hrid: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/employee/${hrid}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getCGGSkillCount(empID: number, titleID: number, positionID: number) {
	const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/cgg/count/${empID}/${titleID}/${positionID}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getskillList(currentUserID: number, titleid: number, positionid: number) {
	const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/${currentUserID}/${titleid}/${positionid}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getskillListByTitle(titleid: any = []) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        params: {
            titleid: titleid,
        },
    });
    return response;
}
export async function getGoogleGroupsList() {
	const email: string = getValueFromToken('email');
	const response = await axios.get(`${serviceCGPURL}/api/cgp/google/${email}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function getGoogleMembersList(name: string, admin: string) {
	const response = await axios.get(`${serviceCGPURL}/api/cgp/google/${name}/${admin}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export async function fetchDepartmentUsers(params: any) {
    try {
        const response = await axios.get(
            `${railsAPI}/api/v1/project_members/employees_list`,
            {
                headers: {
                    Authorization: Cookies.get('idToken'),
                },
                params: {
                    project_id: params.projectId,
                    department_id: params.departmentId,
                    requistion: params.requisitionFlag,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
}
export async function addProjectMember(params: any) {
    const postData = {
        project_members: [
            {
                project_id: params.projectId,
                emp_id: params.Name,
                requisition_code: params.Name,
                requisition: params.requisition,
                department_id: params.department,
                start_date: params.StartDate,
                end_date: params.EndDate,
                created_by: params.userId,
                is_active: 1,
                description: params.description,
                member_sysvine_role_id: params.SysvineRole,
                member_client_role_id: params.ClientRole,
                member_status_id: params.Status,
                project_member_crf: params.Role,
                member_cost_type_id: params.costType,
                groups: params.group
            },
        ],
    };

    const response = await axios.post(`${railsAPI}/api/v1/project_members`, postData, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}
export async function getempTitlePositionHistorydetails(emptitlepositionId: number) {
    const response = await axios.get(
        `${serviceCGPURL}/api/cgp/emptitlepositionhistory/${emptitlepositionId}`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
        },
    );
    return response;
}
export async function updateProjectMemberDetail(params: any) {
    let emailAddress = getValueFromToken('email');
    const response = await axios.patch(
        `${railsAPI}/api/v1/project_members/edit`,
        {
            project_members: [
                {
                    id: params.id,
                    start_date: params.StartDate,
                    end_date: params.EndDate,
                    description: params.Description,
                    member_client_role_id: params.ClientRole,
                    project_member_crf: params.Role,
                    member_status_id: params.Status,
                    modified_by: params.ModifiedBy,
                    member_sysvine_role_id: params.SysvineRole,
                    member_cost_type_id: params.costType,
                    groups: params.group
                },
            ],
        },
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
        },
    );
    return response;
}
export async function searchProjectMembers(params: any) {
    try {
        const response = await axios.get(
            `${railsAPI}/api/v1/project_members`,
            {
                headers: {
                    Authorization: Cookies.get('idToken'),
                },
                params: {
                    project_id: params.projectId,
                    search: params.searchValue,
                    requisition: true
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
}
export async function searchRoster(params: any) {
    try {
        const response = await axios.get(
            `${railsAPI}/api/v1/project_members`,
            {
                headers: {
                    Authorization: Cookies.get('idToken'),
                },
                params: {
                    project_id: params.projectID,
                    search_roster: params.searchValue
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
}
export async function deleteProjectMember(params: any) {
    try {
        const response = await axios.delete(
            `${railsAPI}/api/v1/project_members/remove`,
            {
                headers: {
                    Authorization: Cookies.get('idToken'),
                },
                params: {
                    ids: [params.id],
                    requisition: true
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
}
export async function getAuditLogs(params: any) {

    const response = await axios.get(
        `${railsAPI}/api/v1/project_members/review_history`,
        {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
            params: {
                id: params.id,
                created_user_id: params.created_by,
            },
        }
    );
    return response;
}
export async function createNewProject(params: any) {
    const postData = {
      tm_project: {
        project_name: params.project,
        project_status: params.status,
        base_project: params.baseProject,
        description: params.description,
        client_id: params.account,
        currency_id: params.currency,
        project_type: params.projectType,
        estimated_hrs: params.estimationHours,
        start_date: params.startDate,
        end_date: params.endDate,
        initiated_date: params.initiatedDate,
        hold_date: params.holdDate,
        completed_date: params.completedDate,
        tqi_status: params.tqiStatus,
        created_by: params.createdBy,
        is_active: 1,
        created: params.created,
        modified: params.modified,
        department_id: params.department,
        unit_id: params.businessUnit,

      },
    };
  
    const response = await axios.post(`${railsAPI}/api/v1/projects/create`, postData, {
      headers: {
        Authorization: Cookies.get('idToken'),
      },
    });
  
    return response;
      }

      export async function getCreateAccess() {
        let emailAddress = getValueFromToken('email');
        const response = await axios.get(`${railsAPI}/api/v1/projects/create_access`, {
            headers: {
                Authorization: Cookies.get('idToken'),
              },
            params: {
                email: emailAddress,
            },
        });
        return response;
    }

    export async function getProjectNames(params: any) {
        const response = await axios.get(`${railsAPI}/api/v1/projects/project_names`, {
            headers: {
                Authorization: Cookies.get('idToken'),
              },
            params: {
                account: params.account
            },
        });
        return response;
    }

    export async function employeeStatusFilter(params: any ) {
        const groupResponse: any = getGroupFromCookie();
        let emailAddress = getValueFromToken('email');
        let url = new URL(window.location.href);
        const pathname = url?.pathname?.split('/');
        const isMyTeam = pathname[pathname.length - 2] === HR;
        const isHR =
            (groupResponse.length > 0 &&
                groupResponse?.some((o1: string) => hrGroups?.some((o2: string) => o1 === o2)));
        const response = await axios.get(`${serviceURL}/api/employees/employee-filter`, {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
            params: {
                employeeStatus: params?.employeeStatus,
                userStatus: params?.userStatus,
                searchInput: params?.searchInput,
                email: emailAddress,
                isHR: isHR,
                isMyTeam: !isMyTeam,
            },
            withCredentials: true,
        });
        return response;
    }
    export async function getCostTypes() {
        const response = await axios.get(`${railsAPI}/api/v1/project_members/cost_types`, {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
        });
        return response;
    }



    export async function getTimeEntries(params: any): Promise<GetTimeEntriesResponse> {
        let emailAddress = getValueFromToken('email');
        const payload = {
            time_entry: {
                start_date: params.startDate,
                end_date: params.endDate,
                account_code: params.accountName,
                project_code: params.projectName,
                email: emailAddress,
            },
            key: agvnAccessKey
        };
        const response = await axios.get(`${agvnURL}/api/v1/time_entries`,  {
            headers: {
                Authorization: Cookies.get('idToken'),
            },
            params: payload
        });

        return Promise.resolve(response.data);
}

    export async function getGroups(params: any) {
        const response = await axios.get(`${railsAPI}/api/v1/google_groups`, {
            headers: {
                Authorization: Cookies.get('idToken'),
              },
            params:{
                project_id: params.projectId
            }
        });
        return response;
    }

    export async function getUserGroups(params: any) {
        const response = await axios.get(`${railsAPI}/api/v1/google_groups/get_user_groups`, {
            headers: {
                Authorization: Cookies.get('idToken'),
              },
            params:{
                project_id: params.projectId,
                user_id: params.userId
            }
        });
        return response;
    }

    export async function getJobStatus(jobId: number) {
        const response = await axios.get(`${railsAPI}/api/v1/jobs/status/${jobId}`, {
            headers: {
                Authorization: Cookies.get('idToken'),
              }
        });
        return response;
    }
//email template for all risk
export async function emailRiskNotification(notificationtype: number, params: IRiskDetails | undefined, params2: IRiskReview | undefined, emailids: any, previousData: IRiskDetails | undefined) {
	let emailAddress = getValueFromToken('email');
	const data = {
		email: emailAddress,
		notificationtype: notificationtype,
		riskDetails: params,
		mgmtReview: params2,
		emailids: emailids,
		previousData: previousData,
	}
	const response = await axios.post(`${serviceCGPURL}/api/common/Email/RiskNotification`, data, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}
export function normalEmail(senderName: string,fromAddress: string, toAddress: string, cc: string, bcc: string, subject: string, body: string)
{
	const data = {
		Name: senderName,
		Fromaddress: fromAddress,
		Toaddress: toAddress,
		cc: cc,
		bcc: bcc,
		Subject: subject,
		Body: body
	}
	const response = axios.post(`${serviceCGPURL}/api/common/Email/SendMail`, data, {
		headers: {
			Authorization: Cookies.get('idToken'),
		},
	});
	return response;
}

export async function checkHrManager(emp_id: any) {
    console.log("Emp id", emp_id);
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/checkIsHRManager/${emp_id}`,{
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getCGGskillList(currentUserID: number, titleid: number, positionid: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/cgg/${currentUserID}/${titleid}/${positionid}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getCategoryName() {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skillcategory`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getSkillName() {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/skillList`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getTitlePosition() {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/TitlePositions`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getskillListWithCategoriesGroup() {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/skillListWithCategoryGroup`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function insertSkillCategory(Category: any) {
    const response = await axios.post(`${serviceCGPURL}/api/cgp/skill/AddSkillCategory`, Category,{
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function insertSkillName(skill: any) {
    const response = await axios.post(`${serviceCGPURL}/api/cgp/skill/AddSkill`, skill,{ 
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function updatedSkillList(currentUserID: number, CGGData: any) {
    const url = `${serviceCGPURL}/api/cgp/skill/UpdateCGG`;
    console.log("CGG Data", CGGData);
    const response = await axios.put(url, CGGData,{
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function UploadCGG(CGGData: any) {
    const url = `${serviceCGPURL}/api/cgp/skill/UploadCGG`;
    console.log("CGG Data", CGGData);
    const response = await axios.post(url, CGGData,{
        timeout: 300000,
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getCGGSkillCountUpload(titleid: number, positionid: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/GetCGGSkillCount/${titleid}/${positionid}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export async function getEmployeeListByTitlePosition(titleid: number, positionid: number) {
    const response = await axios.get(`${serviceCGPURL}/api/cgp/skill/AllEmployeeByTitlePositions/${titleid}/${positionid}`, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
    });
    return response;
}

export default USERS;
