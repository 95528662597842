import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import moment from 'moment-timezone';
import React from 'react';
import {Type} from '../ProgressVine/types/index';
import { EmplStatus } from '../components/EmployeeBoarding/types/index';

export const processVinePageTitle = 'Projects';
export const RiskVineTitle = 'Risks';
export const CGPPageTitle = 'My CGP';
export const MyTeamTitle = 'CGP';
export const CGPHRPageTitle = 'CGP';
export const CGGPageTitle = 'CGG';
export const serviceConfirmationPageTitle = 'My Probation';
export const serviceConfirmationMyteamPageTitle = 'Probations';
export const serviceConfirmationHRPageTitle = 'Probations';
export const serviceConfirmationViewPageTitle = 'My Team';
export const serviceConfirmationAddPageTitle = 'My Team';
export const employeeBoardingHRTitle = "Employees";
export const OCPPageTitle = 'Sourcing';
export const projectMemberShipTitle = "Projects"

const today = new Date();
export const currentYear = '-' + today.getFullYear();

export const unauthorizedErrorMessage = 'Request failed with status code 401';
export const forbiddenErrorMessage = 'Request failed with status code 403';
export const somethingWentWrongMessage = 'Something went wrong';

export const getCodeFromRedirect = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    return code;
};

const dev = {
    url: {
        KEYCLOAK_BASE_URL: 'https://qe-ssovine.sysvine.com/auth/',
        KEYCLOAK_REALM: 'Sysvine',
        KEYCLOAK_CLIENT_ID: 'corpvine',
    },
};

export const prvnGroups = ['SYVN.PRVN.TEAM', 'SYVN.PRVN.LEADS'];
export const leadsGroups = ['SYVN.DL', 'SYVN.DH', 'SYVN.LEADERSHIP', 'SYVN.LEADS'];
export const CPVN_ADMIN = ['SYVN.CPVN.ADMIN'];
export const onlyleadsGroups = ['SYVN.LEADERSHIP', 'SYVN.LEADS'];
export const hrGroups = [
    'SYVN.HRDI.DL',
    'SYVN.HRDI.DH',
    'SYVN.HRDI.LEADS',
    'SYVN.HRDI.TEAM',
    'SYVN.LEADERSHIP',
];
export const vimsGroups = [
    'SYVN.LEADERSHIP',
    'SYVN.HRDI.TEAM',
    'SYVN.DH',
    'SYVN.SFS.TEAM',
    'SYVN.CPVN.LEADS',
    'SYVN.CPVN.QE'
];

export const dlGroups = ['SYVN.DL'];
export const onlyDhGroups = ['SYVN.DH'];

export const getValueFromToken = (value: string) => {
    const token = Cookies?.get('idToken')!;
    if (token != '') {
        const parsedToken: any = jwt(token);
        if (value === 'groups' && Cookies.get('groups') != null) {
            const groups = JSON?.parse(Cookies.get('groups') || '')!;
            return groups;
        }
        return parsedToken[value];
    }
};

export const getGroupFromCookie = () => {
    const userDetailsCookie: any = Cookies.get('userDetails');
    let groupResponse: any = [];
    if (userDetailsCookie !== undefined && userDetailsCookie !== '') {
        groupResponse = JSON.parse(userDetailsCookie)?.groups || [];
    }
    return groupResponse;
};

export const Grade = [
    { value: 'A', text: 'A' },
    { value: 'B', text: 'B' },
    { value: 'C', text: 'C' },
    { value: 'D', text: 'D' },
    { value: 'E', text: 'E' },
    { value: 'F', text: 'F' },
];

export const DefaultScores = [
    { value: '10', text: '10' },
    { value: '20', text: '20' },
    { value: '30', text: '30' },
    { value: '40', text: '40' },
    { value: '50', text: '50' },
    { value: '60', text: '60' },
    { value: '70', text: '70' },
    { value: '80', text: '80' },
    { value: '90', text: '90' },
    { value: '100', text: '100' },
];

export const DefaultScoresWithNA = [
    ...DefaultScores,
    { value: '-1', text: 'N/A' },
]

export const Quarter = [
    { value: 'Jan - Mar', text: 'Jan - Mar' },
    { value: 'Apr - Jun', text: 'Apr - Jun' },
    { value: 'Jul - Sep', text: 'Jul - Sep' },
    { value: 'Oct - Dec', text: 'Oct - Dec' },
];

export const GROUPS = {
    APPROVER: ['SYVN.DL', 'SYVN.DH'],
    FINALIZER: ['SYVN.PRVN.LEADS', 'SYVN.PRVN.TEAM'],
    CRM: ['SYVN.CRM.TEAM', 'SYVN.LEADERSHIP'],
    VIEWER: ['SYVN.SYSVINERS'],
};

export const tarStatus = {
    0: '',
    1: 'Requested',
    2: 'Approved',
    3: 'Approver Rejected',
    4: 'Finalized',
    5: 'Finalizer Rejected',
};

export const activeTab: any = {
    Approve: 'Approve',
    Reject: 'Reject',
    Finalize: 'Finalize',
};

export const ProjectStatusOptions: any = [
    { key: "Active", value: "Active" },
    { key: "Closed", value: "Closed" },
    { key: "Completed", value: "Completed" },
    { key: "Deferred", value: "Deferred" },
    { key: "Draft", value: "Draft" },
    { key: "Hold", value: "Hold" },
    { key: "In Pipeline", value: "In_Pipeline" },
    { key: "In progress", value: "In-progress" },
    { key: "Initiated", value: "Initiated" },
    { key: "POC", value: "POC" },
    { key: "R&D", value: "R&D" },
    { key: "Support", value: "Support" }
];

export const ProjectStatus: Type.ProjectStatus[] = [
    { key: 1, value: 'Initiated' },
    { key: 2, value: 'Draft' },
    { key: 3, value: 'In-progress' },
    { key: 4, value: 'Hold' },
    { key: 5, value: 'Completed' },
    { key: 6, value: 'In_Pipeline' },
    { key: 7, value: 'POC' },
    { key: 8, value: 'R&D' },
    { key: 9, value: 'Active' },
    { key: 10, value: 'Deferred' },
    { key: 11, value: 'Support' },
    { key: 12, value: 'Closed' },
];

export const ProjectType: Type.ISelect[] = [
    { key: 'EDEV', value: 'EDEV' },
    { key: 'EDOS', value: 'EDOS' },
    { key: 'ESUP', value: 'ESUP' },
    { key: 'MSAG', value: 'MSAG' },
    { key: 'MSCT', value: 'MSCT' },
    { key: 'PROC', value: 'PROC' },
    { key: 'PROD', value: 'PROD' },
    { key: 'RAND', value: 'RAND' },
    { key: 'SAUG', value: 'SAUG' },
    { key: 'MSQE', value: 'MSQE' },
    { key: 'PTFM', value: 'PTFM' },
    { key: 'MONC', value: 'MONC' },
    { key: 'MGLA', value: 'MGLA' },
    { key: 'MOFC', value: 'MOFC' },
    { key: 'MONA', value: 'MONA' },
    { key: 'MGLC', value: 'MGLC' },
    { key: 'MOFA', value: 'MOFA' },
    { key: 'SAOF', value: 'SAOF' },
    { key: 'SAON', value: 'SAON' },
    { key: 'SAGL', value: 'SAGL' }
];

export const EmpStatus: any = [
    { key: "All", value: "All" },
    { key: 5, value: 'Probationary Onboarded' },
    { key: 14, value: 'Probationary SC Review' },
    { key: 15, value: 'Probationary SC Failed' },
    { key: 16, value: 'Probationary SC Extended' },
];


export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const displayDefaultDateTime = () => moment().toISOString();
export const displayDefaultUTCDateTime = () => moment().utc(true);

export const parseDate = (date: any) => {
    return date
        ? moment(date).format('DD-MMM-YYYY HH:mm').toUpperCase() +
        ' - ' +
        moment(date)?.tz('Asia/Calcutta', true).fromNow()
        : ' - ';
};

export const validateExpiredToken = (errorMessage: string) => {
    if (errorMessage === unauthorizedErrorMessage || errorMessage.includes('401')) {
        Cookies.remove('groups');
        Cookies.set('idToken', '');
        localStorage.removeItem('employeeId');
        return false;
    }
    return true;
};

export const validateForm = (value: any) => {
    let filteredValue = value === -1 ? 'N/A' : value;
    return filteredValue === null || filteredValue === undefined ? '-' : filteredValue;
};

export const validateNullValues = (value: any) => {
    return value === null || value === undefined ? true : false;
};

export const ValidateUrl = (props: any) => {
    const text = props?.text || "";
    // const words = text.trim().split(/[\n\s]+/);
    const formattedText = text.replace(/\\n/g, '\n');
    const words = formattedText.split(/(https?:\/\/\S+)|(\n)/g).filter(Boolean); //Added this to break the links in individual line


    return words.map((word: string, index: number) => {
        if (word.startsWith('https://') || word.startsWith('http://')) {
            return (
                <a key={index} href={word} target='_blank'>
                    {word}
                </a>
            );
        } else if (word === '\n') { //Added this else if to break the lines as given in the notes
            return <br key={index} />;
        } else {
            return <span key={index}>{word}</span>;
        }

    });
};
export const getCurrentQuarter = () => {
    const currentMonth = today.getMonth();
    if (currentMonth >= 0 && currentMonth <= 2) {
        return 'Apr - Jun';
    } else if (currentMonth >= 3 && currentMonth <= 5) {
        return 'Jul - Sep';
    } else if (currentMonth >= 6 && currentMonth <= 8) {
        return 'Oct - Dec';
    } else {
        return 'Jan - Mar';
    }
}

export const KYPBadge = (value: any) => {
    let colorhighlight = "bg-req";
    if (value) {
        if (value == "Met Expectation") {
            colorhighlight = 'bg-ach';
        } 
        else if (value == "Far away") {
            colorhighlight = 'bg-HRD';
        }
        else if (value == "Doing good") {
            colorhighlight = 'bg-in';
        } 
        else if (value == "Making Progress") {
            colorhighlight = 'bg-progress';
        }
        else if (value == "Getting Close") {
            colorhighlight = 'bg-orange';
        }
        else if (value == "N/A") {
            colorhighlight = 'bg-grey';
        }
        else if (value == "") {
            colorhighlight = 'bg-req';
        }
        return "cgp-container-" + colorhighlight;
    }
    else
        return '';
};

export const badge = (value: any) => {
    let colorhighlight = "bg-req";
    if (value) {
        if (value == "Requested" || value == "Primary Change Requested") {
            colorhighlight = 'bg-req';
        }
        else if (value == "A" || value == "Achieved" || value == "Acquired" || value == "Met Expectation") {
            colorhighlight = 'bg-ach';
        } else if (value == "B" || value == "Approved" || value == "Primary Change DH Approved") {
            colorhighlight = 'bg-app';
        } else if (value == "C") {
            colorhighlight = 'bg-gradeC';
        }
        else if (value == "Primary Change HR Approved") {
            colorhighlight = 'bg-prc-hr-app';
        }
        else if (value == "D" || value == "In Progress" || value == "Deficient") {
            colorhighlight = 'bg-in';
        } else if (value == "E" || value == "Non-Performance") {
            colorhighlight = 'bg-non';
        }
        else if (value == "F") {
            colorhighlight = 'bg-gradeF';
        }
        else if (value == "Goal Met") {
            colorhighlight = 'bg-gm';
        }
        else if (value == "Primary Change DH Denied" || value == "Denied") {
            colorhighlight = 'bg-DHD';
        } else if (value == "Primary Change HR Denied" || value == "HR Denied" || value == "Far away") {
            colorhighlight = 'bg-HRD';
        }
        else if (value == "Getting Close") {
            colorhighlight = 'bg-orange';
        }
        else if (value == "N/A") {
            colorhighlight = 'bg-grey';
        }
        else if (value == "") {
            colorhighlight = 'bg-req';
        }
        return "cgp-container-" + colorhighlight;
    }
    else
        return '';
};
export const config = dev;
export const PRVN_TEAM = 'SYVN.PRVN.TEAM';
export const PRVN_LEADS = 'SYVN.PRVN.LEADS';
export const DH = 'SYVN.DH';
export const DL = 'SYVN.DL';
export const LEADS = 'SYVN.LEADS';
export const HRD_TEAM = 'SYVN.HRD.TEAM';    
export const FND_TEAM = 'SYVN.FND.TEAM';
export const HRDI_TEAM = 'SYVN.HRDI.TEAM';
export const FNDI_TEAM = 'SYVN.FNDI.TEAM';
export const CRM_TEAM = 'SYVN.CRM.TEAM';
export const BOD_INDIA = 'SYVN.BOD.INDIA';
export const BOD_USA = 'SYVN.BOD.USA';
export const LEADERSHIP = 'SYVN.LEADERSHIP';
export const PROGRESSVINE_VIEW = 'AUTHZ_PROGRESSVINE-VIEW';
export const PROGRESSVINE_REQUEST = 'AUTHZ_PROGRESSVINE-REQUEST';
export const PROGRESSVINE_APPROVE = 'AUTHZ_PROGRESSVINE-APPROVE';
export const PROGRESSVINE_FINALIZE = 'AUTHZ_PROGRESSVINE-FINALIZE';
export const CRM_LEADS = 'SYVN.CRM.LEADS';
export const FND_DH = 'SYVN.FND.DH';
export const CSAT_VIEW = 'AUTHZ_CSAT-VIEW';
export const CSAT_REQUEST = 'AUTHZ_CSAT-REQUEST';
export const CSAT_APPROVE = 'AUTHZ_CSAT-APPROVE';
export const CSAT_FINALIZE = 'AUTHZ_CSAT-FINALIZE';

export const EmployeeStatusList: EmplStatus.EmployeeStatus[] = [
	{ key: 6, value: 'Consultant' },
	{ key: 7, value: 'Deputation' },
	{ key: 19, value: 'Disability Leave' },
	{ key: 20, value: 'Emergency Leave' },
	{ key: 17, value: 'Family Leave' },
	{ key: 1, value: 'Full Time' },
	{ key: 13, value: 'In-Separation' },
	{ key: 12, value: 'Intern' },
	{ key: 9, value: 'Left' },
	{ key: 18, value: 'Maternity Leave' },
	{ key: 2, value: 'Part Time' },
	{ key: 3, value: 'Permanent' },
	{ key: 5, value: 'Probationary Onboarded' },
	{ key: 16, value: 'Probationary SC Extended' },
	{ key: 15, value: 'Probationary SC Failed' },
	{ key: 14, value: 'Probationary SC Review' },
	{ key: 8, value: 'Resigned' },
	{ key: 10, value: 'Suspended' },
	{ key: 4, value: 'Temporary' },
	{ key: 11, value: 'Transferee' },
];

export const UserStatusList: EmplStatus.UserStatus[] = [
	{ key: 1, value: 'Active' },
	{ key: 0, value: 'Inactive' },
];