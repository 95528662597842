import axios from './AxiosService';
import Cookies from 'js-cookie';
import { getValueFromToken, getGroupFromCookie, hrGroups } from '../../App/Constants';
import { serviceURL } from '../../types';

export async function createGmailAccount(params: any) {    
	const response = await axios.post(`${serviceURL}/api/user`, params, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        withCredentials: true
    });
	return response;
}

export async function getGmailAccount(userEmail: any) {
	const response = await axios.get(`${serviceURL}/api/user/detail?userEmail=${userEmail}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		}
	});
	return response;
}

export async function employeeDetailsByID(empID: any) {
	const response = await axios.get(`${serviceURL}/api/employees/employee-details-by-id?empID=${empID}`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		}
	});
	return response;
}

export async function getExistingUsers() {
	const response = await axios.get(`${serviceURL}/api/user/existing-accounts`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		}
	}); 
	return response;
}

export async function addUserToGoogleGroups(params: any) {
	const response = await axios.post(`${serviceURL}/corpvine/api/groups`, params.userDetails, {
        headers: {
            Authorization: Cookies.get('idToken'),
        },
        withCredentials: true
    });
	return response;
}

export async function getAllDivisions() {
	
	const response = await axios.get(`${serviceURL}/api/employees/divisions`, {
		headers: {
			Authorization: Cookies.get('idToken'),
		}
	}); 
	return response;
}



export async function updateDivision(
	id: any | undefined,
	divisions: { id: string; code: string }[] | undefined
  ) {
	try {
	  const response = await axios.put(
		`${serviceURL}/api/employees/updateDivision?userId=${id}`,
		{
		  divisions, 
		},
		{
		  headers: {
			Authorization: Cookies.get("idToken"),
		  },
		}
	  );
  
	  return response;
	} catch (error) {
	  console.error("Error updating divisions:", error);
	  throw error;
	}
  }